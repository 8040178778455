import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Menu,
  MenuItem,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core'
import DescriptionIcon from '@material-ui/icons/Description'
import DeleteIcon from '@material-ui/icons/Delete'
import PublishIcon from '@material-ui/icons/Publish'
import { CheckCircle, Close, ErrorRounded, MoreVert } from '@material-ui/icons'
import Skeleton from '@material-ui/lab/Skeleton'
import axios from 'axios'
import React, { useContext, useState } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { BACKEND_URL } from 'src/api/axios/api'
import { GetAccessToken } from 'src/api/axios/helper'
import { ICertificationPeriod } from 'src/api/models'
import { AuthContext } from 'src/context/AuthenticationContext'
import { CertificationPeriodContext } from 'src/context/CertificationPeriodContext'

import { formatDateAndTimeLocal } from 'src/utils/date'
import UploadDragDrop from 'src/components/UploadDragDrop'
import { BusinessContext } from 'src/context/BusinessContext'
import { useSnackbar } from 'notistack'
import { notistackOptions } from 'src/configs/notistackOptions'
import { MaxFileSize, PermissionCodeAccess } from 'src/utils/constants'
import { AbilityContext } from 'src/context/Can'
import DeleteAdditionalRequestModal from './DeleteAdditionalRequestModal'
import {
  DialogTitleStyled,
  UploadItem,
} from '../../../pages/NewDocumentRegistration/style'

const notistackSuccess = notistackOptions('success')
const notistackError = notistackOptions('error')

interface AdditionalDocumentMainProps {
  documentRequestId: number
  documentData: any
}

const MAX_SIZE_FILE = MaxFileSize

const AdditionalDocumentMain: React.FC<AdditionalDocumentMainProps> = ({
  documentRequestId,
  documentData,
}: AdditionalDocumentMainProps) => {
  const { enqueueSnackbar } = useSnackbar()
  const userToken = GetAccessToken()
  const queryClient = useQueryClient()
  const { userPermissions } = useContext(AuthContext)
  const businessContext = useContext<any>(BusinessContext)
  const isInternal = userPermissions.type.toLowerCase() === 'internal'

  const certificationPeriodSelected = useContext<ICertificationPeriod>(
    CertificationPeriodContext
  )

  const [selectedIndex, setSelectedIndex] = useState(0)
  const [selectedItem, setSelectedItem] = useState<any>()
  const [anchorEl, setAnchorEl] = useState(null)
  const [files, setFiles] = useState<File[] | null>(null)
  const [openRequestDialog, setOpenRequestDialog] = useState<boolean>(false)
  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false)
  const [requestName, setRequestName] = useState<string>()
  const [requestDescription, setRequestDescription] = useState<string>('')
  const ability = useContext(AbilityContext)

  const canManage =
    ability.can(
      PermissionCodeAccess.ComplianceManagementSystem_Basic_ManageCertification,
      'any'
    ) ||
    ability.can(
      PermissionCodeAccess.ComplianceManagementSystem_Basic_ManageFiles,
      'any'
    ) ||
    ability.can(
      PermissionCodeAccess.ComplianceManagementSystem_Basic_Internal,
      'any'
    ) ||
    ability.can(
      PermissionCodeAccess.ComplianceManagementSystem_Basic_External,
      'any'
    )

  const canView =
    ability.can(
      PermissionCodeAccess.ComplianceManagementSystem_Basic_ViewAccess,
      'any'
    ) && !canManage

  const readOnly = canView

  const handleListItemClick = (index: number, item: any) => {
    setSelectedIndex(index)
    setFiles(null)
  }

  const handleFileRejected = () => {
    enqueueSnackbar('The file has been rejected.', notistackOptions('warning'))
  }

  const additionalDocumentDataQuery = useQuery({
    queryKey: ['getAdditionalDocuments', documentRequestId],
    queryFn: async () =>
      axios({
        method: 'get',
        url: `${BACKEND_URL}/additionalDocumentRequest`,
        params: { businessUploadRequestId: documentRequestId },
        headers: {
          Authorization: userToken,
        },
      }).then((result: any) => {
        return result.data
      }),
  })

  const additionalDocumentsList = additionalDocumentDataQuery?.data

  const getStatusIcon = (status: string) => {
    if (status) {
      if (status !== 'Pending Upload')
        return (
          <Tooltip title="Uploaded">
            <CheckCircle style={{ color: 'green' }} />
          </Tooltip>
        )
    }

    return (
      <Tooltip title={status}>
        <ErrorRounded style={{ color: '#FFA500' }} />
      </Tooltip>
    )
  }

  const open = Boolean(anchorEl)
  const handleClick = (event: any, item: any) => {
    setAnchorEl(event.currentTarget)
    setSelectedItem(item)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleDroppedFiles = (acceptedFiles: File[]) => {
    setFiles(acceptedFiles)
  }

  const handleUploadFileRemove = (index: number) => {
    if (files) {
      setFiles(files.filter((f) => f.name !== files[index].name))
    }
  }

  const mutationUploadFile = useMutation({
    mutationFn: async (formData: FormData) => {
      return axios({
        method: 'post',
        url: `${BACKEND_URL}/AdditionalDocumentRequest/Upload`,
        data: formData,
        params: {
          businessUploadRequestId: documentRequestId,
          additionalRequestId: additionalDocumentsList[selectedIndex].id,
          businessId: businessContext.id,
        },
        headers: {
          Authorization: userToken,
        },
      })
    },
    onSuccess: async () => {
      setFiles(null)
      enqueueSnackbar(
        'File(s) uploaded successfully',
        notistackOptions('success')
      )

      queryClient.refetchQueries({
        queryKey: 'getfilesUploaded',
      })

      queryClient.refetchQueries({
        queryKey: 'documentRequest',
      })

      queryClient.refetchQueries({
        queryKey: 'getHistory',
      })

      queryClient.refetchQueries({
        queryKey: 'uploadProgressPercent',
      })
      queryClient.refetchQueries({
        queryKey: 'uploadedProgressPercent',
      })

      await queryClient.refetchQueries({
        queryKey: 'getAdditionalDocuments',
      })
    },
    onError: (error, variables, context) => {
      enqueueSnackbar('Error uploading file', notistackOptions('error'))
    },
  })

  const handleFileUpload = () => {
    if (files && files.length) {
      const formData = new FormData()

      files.forEach((file: File) => {
        formData.append('files', file, file.name)
      })

      mutationUploadFile.mutate(formData)
    }
  }

  const createRequestMutation = useMutation({
    mutationFn: async () =>
      axios({
        method: 'post',
        url: `${BACKEND_URL}/additionalDocumentRequest`,
        data: {
          businessUploadRequestId: documentRequestId || null,
          documentId: documentData.id,
          businessId: businessContext.id,
          businessName: businessContext.name,
          businessTypeName: businessContext.type,
          buyerType: businessContext.buyerType,
          name: requestName,
          description: requestDescription,
          servicerType: businessContext?.servicerType,
        },
        headers: {
          Authorization: userToken,
        },
      }),
    onSuccess: () => {
      enqueueSnackbar('Document has been requested', notistackSuccess)
      queryClient.refetchQueries({ queryKey: 'documentRequest' })
      queryClient.refetchQueries({ queryKey: 'getAdditionalDocuments' })
      queryClient.refetchQueries({ queryKey: 'documentsRequired' })
      queryClient.refetchQueries({ queryKey: 'uploadProgressPercent' })
      queryClient.refetchQueries({
        queryKey: 'uploadedProgressPercent',
      })
      queryClient.refetchQueries({ queryKey: 'getHistory' })
      setOpenRequestDialog(false)
      setRequestName('')
      setRequestDescription('')
    },
    onError: () => {
      enqueueSnackbar('Error', notistackError)
    },
  })

  const deleteRequestMutation = useMutation({
    mutationKey: ['deleteAdditionalDocuments'],
    mutationFn: () =>
      axios({
        method: 'delete',
        url: `${BACKEND_URL}/additionalDocumentRequest`,
        params: {
          additionalRequestId: selectedItem.id,
          requestId: documentRequestId,
        },
        headers: {
          Authorization: userToken,
        },
      }),
    onSuccess: async () => {
      enqueueSnackbar(
        'Document requested deleted successfully',
        notistackOptions('success')
      )
      setFiles(null)

      handleClose()
      setOpenDeleteDialog(false)
      await queryClient.refetchQueries({ queryKey: 'getAdditionalDocuments' })
      queryClient.refetchQueries({ queryKey: 'documentsRequired' })
      queryClient.refetchQueries({ queryKey: 'uploadProgressPercent' })
      queryClient.refetchQueries({
        queryKey: 'uploadedProgressPercent',
      })
      queryClient.refetchQueries({ queryKey: 'getHistory' })
    },
    onError: () => {
      enqueueSnackbar('Error', notistackOptions('error'))
    },
  })

  if (certificationPeriodSelected.id !== null && !additionalDocumentsList) {
    return <>There is no document request</>
  }

  const handleAddRequest = () => createRequestMutation.mutate()
  const handleDeleteRequest = () => deleteRequestMutation.mutate()

  return (
    <Box display="flex" flexDirection="column">
      <Box display="flex" flexDirection="row" flexGrow={1}>
        <Box borderRight="1px solid #e8e8e8" width="50%" height={400}>
          {!additionalDocumentDataQuery.isLoading &&
          additionalDocumentsList?.length > 0 ? (
            <List style={{ maxHeight: '100%', overflow: 'auto' }}>
              {additionalDocumentsList.map((item: any, index: number) => {
                return (
                  <ListItem
                    button
                    selected={selectedIndex === index}
                    key={item.id}
                    onClick={() => handleListItemClick(index, item)}
                  >
                    <ListItemIcon>{getStatusIcon(item.status)}</ListItemIcon>
                    <ListItemText
                      key={item.id}
                      primary={
                        item.name.length > 33
                          ? `${item.name.substring(0, 30)}...`
                          : item.name
                      }
                      secondary={formatDateAndTimeLocal(item.creationDateUtc)}
                    />
                    {certificationPeriodSelected.id === null &&
                      isInternal &&
                      additionalDocumentsList?.length !== 0 &&
                      !additionalDocumentDataQuery.isLoading &&
                      additionalDocumentsList[index].status ===
                        'Pending Upload' && (
                        <ListItemSecondaryAction>
                          <IconButton
                            key={item.id}
                            id="positioned-button"
                            aria-controls={open ? 'positioned-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? 'true' : undefined}
                            onClick={(event) => handleClick(event, item)}
                          >
                            <MoreVert key={item.id} />
                          </IconButton>
                        </ListItemSecondaryAction>
                      )}
                  </ListItem>
                )
              })}
            </List>
          ) : (
            <>
              {!additionalDocumentsList && (
                <Box ml={6}>
                  <Skeleton height={35} width={330} />
                  <Skeleton height={35} width={330} />
                  <Skeleton height={35} width={330} />
                  <Skeleton height={35} width={330} />
                  <Skeleton height={35} width={330} />
                </Box>
              )}
            </>
          )}
          {additionalDocumentsList?.length === 0 &&
            'There is no document requested'}
        </Box>
        <Box width="50%">
          {additionalDocumentDataQuery.isLoading && (
            <Box ml={9}>
              <Skeleton height={35} width={330} />
              <Skeleton height={35} width={330} />
              <Skeleton height={35} width={330} />
              <Skeleton height={35} width={330} />
              <Skeleton height={35} width={330} />
            </Box>
          )}
          {additionalDocumentsList && additionalDocumentsList.length > 0 && (
            <Box borderBottom="1px solid #e8e8e8">
              <List style={{ lineHeight: '0.5rem' }}>
                <ListItem>
                  <Typography>
                    <b>Status: </b>{' '}
                    {additionalDocumentsList[selectedIndex].status}
                  </Typography>
                </ListItem>
                <ListItem>
                  <Typography>
                    <b>Note: </b>{' '}
                    {additionalDocumentsList[selectedIndex].description}
                  </Typography>
                </ListItem>
                {additionalDocumentsList[selectedIndex].status !==
                  'Pending Upload' && (
                  <ListItem>
                    <Typography>
                      <b>Uploaded On: </b>
                      {formatDateAndTimeLocal(
                        additionalDocumentsList[selectedIndex].uploadedOnUtc
                      )}
                    </Typography>
                  </ListItem>
                )}
                {additionalDocumentsList[selectedIndex].fileName && (
                  <ListItem>
                    <Typography>
                      <b>File Name: </b>
                      {additionalDocumentsList[selectedIndex].fileName}
                    </Typography>
                  </ListItem>
                )}
              </List>
            </Box>
          )}
          {!readOnly &&
            certificationPeriodSelected.id === null &&
            additionalDocumentsList?.length !== 0 &&
            !additionalDocumentDataQuery.isLoading &&
            additionalDocumentsList[selectedIndex].status ===
              'Pending Upload' && (
              <Box display="flex" flexDirection="column" mt={9}>
                {files && files.length > 0 && (
                  <UploadItem>
                    <Grid container>
                      <Grid item xs={12}>
                        <List
                          style={{
                            height: '120px',
                            overflow: 'auto',
                            marginBottom: 4,
                            lineHeight: '0.5rem',
                          }}
                        >
                          {files.map((file: any, idx: number) => {
                            return (
                              <ListItem
                                alignItems="flex-start"
                                key={`item_${idx}`}
                              >
                                <div
                                  className="upload-item-info"
                                  key={`div_${idx}`}
                                >
                                  <DescriptionIcon
                                    key={`DescriptionIcon_${idx}`}
                                    fontSize="small"
                                    color="primary"
                                    className="upload-item-icon"
                                  />
                                  <Typography
                                    variant="body2"
                                    color="textSecondary"
                                    component="span"
                                    key={`Typography_${file.name}`}
                                  >
                                    {file.name.length > 18
                                      ? `${file.name.substring(0, 15)}...`
                                      : file.name}
                                  </Typography>
                                  <IconButton
                                    size="small"
                                    key={`Button_${idx}`}
                                    onClick={() => {
                                      handleUploadFileRemove(idx)
                                    }}
                                  >
                                    <DeleteIcon
                                      key={`DeleteButton_${idx}`}
                                      className="upload-item-icon"
                                      fontSize="small"
                                    />
                                  </IconButton>
                                </div>
                              </ListItem>
                            )
                          })}
                        </List>
                      </Grid>
                    </Grid>
                  </UploadItem>
                )}
                {files == null || files.length === 0 ? (
                  <Box id="box2" display="flex" justifyContent="center">
                    <UploadDragDrop
                      size="large"
                      linkText="Click to Upload"
                      text="PDF files only (max. 200mb)"
                      multiple={false}
                      uploading={mutationUploadFile.isLoading}
                      onDrop={(acceptedFiles) => {
                        handleDroppedFiles(acceptedFiles)
                      }}
                      maxSize={MAX_SIZE_FILE}
                      onDropRejected={handleFileRejected}
                      accept="application/pdf"
                    />
                  </Box>
                ) : (
                  <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="flex-end"
                    alignItems="end"
                  >
                    <Box>
                      <Button
                        style={{ marginTop: 5 }}
                        size="small"
                        variant="contained"
                        color="primary"
                        onClick={() => {
                          handleFileUpload()
                        }}
                        disabled={mutationUploadFile.isLoading}
                        startIcon={
                          mutationUploadFile.isLoading ? (
                            <CircularProgress size={15} color="primary" />
                          ) : (
                            <PublishIcon fontSize="small" />
                          )
                        }
                      >
                        Upload
                      </Button>
                    </Box>
                  </Box>
                )}
              </Box>
            )}
        </Box>
      </Box>
      {certificationPeriodSelected.id === null && isInternal && (
        <Menu
          id="positioned-menu"
          aria-labelledby="positioned-button"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <MenuItem
            onClick={() => setOpenDeleteDialog(true)}
            disabled={readOnly}
          >
            Delete
          </MenuItem>
        </Menu>
      )}
      {certificationPeriodSelected.id === null && isInternal && (
        <>
          <Box display="flex" justifyContent="right">
            <Button
              variant="contained"
              color="primary"
              onClick={() => setOpenRequestDialog(true)}
              disabled={readOnly}
            >
              Request document
            </Button>
          </Box>
          <Dialog
            open={openRequestDialog}
            onClose={() => setOpenRequestDialog(false)}
            aria-labelledby="request-dialog-title"
            aria-describedby="request-dialog-description"
            maxWidth="lg"
            PaperProps={{ style: { width: '500px' } }}
          >
            <DialogTitleStyled id="request-dialog-title">
              <Typography
                variant="body1"
                style={{
                  color: 'white',
                  fontSize: '1.5rem',
                  fontWeight: 400,
                  lineHeight: 1.2,
                  textAlign: 'center',
                }}
              >
                Request Document
              </Typography>
              <IconButton
                aria-label="close"
                onClick={() => setOpenRequestDialog(false)}
                style={{
                  position: 'absolute',
                  right: 8,
                  top: 8,
                  color: 'white',
                }}
              >
                <Close />
              </IconButton>
            </DialogTitleStyled>
            <DialogContent>
              <Box>
                <Box>
                  <TextField
                    name="name"
                    inputProps={{ maxLength: 50 }}
                    value={requestName}
                    onChange={(e) => setRequestName(e.target.value)}
                    placeholder="Name"
                    disabled={createRequestMutation.isLoading}
                  />
                </Box>
                <Box mt={2}>
                  <TextField
                    fullWidth
                    multiline={true}
                    maxRows={4}
                    minRows={4}
                    inputProps={{ maxLength: 250 }}
                    variant="outlined"
                    name="description"
                    value={requestDescription}
                    onChange={(e) => setRequestDescription(e.target.value)}
                    placeholder="Note"
                    disabled={createRequestMutation.isLoading}
                  />
                </Box>
              </Box>
            </DialogContent>
            <DialogActions
              style={{
                justifyContent: 'flex-end',
                paddingLeft: '24px',
                paddingRight: '24px',
              }}
            >
              <Box flexGrow={1}>
                <Typography>
                  Characters remaining: {250 - requestDescription?.length}
                </Typography>
              </Box>
              <Button
                variant="outlined"
                color="secondary"
                onClick={() => {
                  setOpenRequestDialog(false)
                  setRequestName('')
                  setRequestDescription('')
                }}
                disabled={createRequestMutation.isLoading}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                color="primary"
                disabled={
                  !requestName ||
                  !requestDescription ||
                  createRequestMutation.isLoading
                }
                onClick={handleAddRequest}
              >
                Submit
              </Button>
            </DialogActions>
          </Dialog>
        </>
      )}
      {certificationPeriodSelected.id === null && isInternal && (
        <DeleteAdditionalRequestModal
          open={openDeleteDialog}
          disableUI={deleteRequestMutation.isLoading}
          Close={() => setOpenDeleteDialog(false)}
          Confirm={handleDeleteRequest}
        />
      )}
    </Box>
  )
}

export default AdditionalDocumentMain
