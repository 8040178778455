import React, { useContext, useEffect, useState } from 'react'
import { Box, Grid, MenuItem, TextField, Typography } from '@material-ui/core'

import { useQuery } from 'react-query'
import axios from 'axios'
import { AuthContext } from 'src/context/AuthenticationContext'
import { BACKEND_URL, GetAccessToken } from 'src/api/axios/api'
import hardcodeData from 'src/utils/hardcodeData'

import {
  getBusinessesQuery,
  getBuyerQuery,
} from 'src/api/operations/get/business'
import { ICategory, ICertificationPeriod } from 'src/api/models'
import { useLocation } from 'react-router-dom'
import { BusinessContext } from 'src/context/BusinessContext'
import { CategoriesTabs } from 'src/components/CategoryTabs'
import { DocumentCardMain } from 'src/components/DocumentCardMain'
import { CertificationPeriodContext } from 'src/context/CertificationPeriodContext'
import Loader from 'src/components/Loader'
import Skeleton from '@material-ui/lab/Skeleton'
import { AbilityContext } from 'src/context/Can'
import { PermissionCodeAccess } from 'src/utils/constants'

const DocumentRepository: React.FC = () => {
  const [selectedBusinessStatus, setSelectedBusinessStatus] = useState<any>()
  const [selectedBusiness, setSelectedBusiness] = useState<any>()
  const [certifiedBusinesses, setCertifiedBusinesses] = useState<any[]>()

  const [businesses, setBusinesses] = useState<any[]>()

  const [availableBusinesses, setAvailableBusinesses] = useState<any[]>()

  const [availablePeriods, setAvailablePeriods] = useState<any[]>()

  const { userPermissions, profileClient } = useContext(AuthContext)
  const [selectedCertificationPeriod, setSelectedCertificationPeriod] =
    useState<ICertificationPeriod>({
      id: null,
      certificationDate: 'Select a period',
    })
  const userToken = GetAccessToken()
  const { search } = useLocation()
  const queryString = new URLSearchParams(search)
  const categoryId = queryString.get('category')
  const businessId = queryString.get('businessId')
  queryString.delete('businessId')

  const isInternal = userPermissions.type.toLowerCase() === 'internal'
  const ability = useContext(AbilityContext)

  const { isLoading: loadingBusinesses } = useQuery({
    enabled: certifiedBusinesses && certifiedBusinesses?.length > 0,
    queryKey: ['getbusinesses', certifiedBusinesses],
    queryFn: async () => {
      return axios({
        url: process.env.REACT_APP_CONTROLPANEL,
        method: 'post',
        headers: {
          Authorization: userToken,
        },
        data: {
          query: `${getBusinessesQuery(
            profileClient ? profileClient.Id : null
          )}`,
        },
      }).then((result: any) => {
        setBusinesses(result.data.data.getBusinesses.businesses)

        const available = getAvailableBusinesses(
          result.data.data.getBusinesses.businesses
        )

        setAvailableBusinesses(available)

        if (available.length === 1) handleSelectedBusiness(available[0])
      })
    },
    cacheTime: 0,
  })

  const handleSelectedBusiness = (business: any) => {
    setSelectedBusiness(business)
  }

  const buyerTypeQuery = useQuery({
    enabled: !!selectedBusiness && selectedBusiness.type === 'Buyer',
    queryKey: ['buyerType', selectedBusiness],
    queryFn: async () => {
      return axios({
        url: process.env.REACT_APP_CONTROLPANEL,
        method: 'post',
        headers: {
          Authorization: userToken,
        },
        data: {
          query: `${getBuyerQuery(selectedBusiness.id)}`,
        },
      }).then((result: any) => {
        return result.data.data.getBuyer.buyerType
      })
    },
    cacheTime: 0,
  })

  const { isLoading: loadingCertifiedBusiness } = useQuery({
    queryKey: ['certifiedBusinessQuery'],
    queryFn: async () => {
      return axios({
        url: `${BACKEND_URL}/certification/getCertifiedBusinesses`,
        method: 'get',
        headers: {
          Authorization: userToken,
        },
      }).then((result: any) => {
        setCertifiedBusinesses(result.data)
      })
    },
    cacheTime: 0,
  })

  const getAvailableBusinesses = (values: any[]) => {
    if (
      isInternal &&
      certifiedBusinesses &&
      selectedBusinessStatus &&
      selectedBusinessStatus !== null
    ) {
      return values.filter(
        (f: any) =>
          f.status === selectedBusinessStatus.value &&
          ((!isInternal &&
            f.type.toLowerCase() === userPermissions.type.toLowerCase()) ||
            isInternal) &&
          certifiedBusinesses.includes(f.id)
      )
    }

    return values.filter(
      (f: any) =>
        ((!isInternal &&
          f.type.toLowerCase() === userPermissions.type.toLowerCase()) ||
          isInternal) &&
        certifiedBusinesses?.includes(f.id)
    )
  }

  useEffect(() => {
    if (businesses && certifiedBusinesses) {
      if (selectedBusinessStatus !== null) {
        const availableAux = getAvailableBusinesses(businesses)

        const filteredBusiness = availableAux?.filter(
          (f: any) => f.status === selectedBusinessStatus.value
        )

        setAvailableBusinesses(filteredBusiness)

        if (filteredBusiness != null && filteredBusiness.length > 0) {
          handleSelectedBusiness(filteredBusiness[0])
        } else {
          handleSelectedBusiness(null)
        }
      } else {
        handleSelectedBusiness(
          businesses?.filter((f: any) => certifiedBusinesses.includes(f.id))[0]
        )
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedBusinessStatus])

  const categoryQuery = useQuery({
    enabled: !!selectedBusiness && !buyerTypeQuery.isLoading,
    queryKey: ['categoryType', selectedBusiness],
    queryFn: async () => {
      return axios({
        method: 'get',
        url: `${BACKEND_URL}/category`,
        headers: {
          Authorization: userToken,
        },
        params: {
          isEnabled: true,
          businessType: selectedBusiness.type,
          buyerType: buyerTypeQuery ? buyerTypeQuery.data : '',
          servicerType: selectedBusiness?.servicerType,
        },
      }).then((result: any) => {
        return result.data.categories
      })
    },
    cacheTime: 0,
  })

  const categories: ICategory[] = categoryQuery.data
  const categorySelected = categories?.find(
    (x) => x.id.toString() === categoryId
  )

  const noCategoriesRegistered =
    categories !== undefined && categories.length === 0

  const { isLoading: loadingCertificationPeriod } = useQuery({
    enabled: !!selectedBusiness,
    queryKey: ['getCertificationPeriodQuery', selectedBusiness],
    queryFn: async () => {
      return axios({
        method: 'get',
        url: `${BACKEND_URL}/actions/certificationperiod/${selectedBusiness.id}`,
        headers: {
          Authorization: userToken,
        },
      }).then((result: any) => {
        setAvailablePeriods(result.data)
        setSelectedCertificationPeriod(result.data[0])
      })
    },
    cacheTime: 0,
  })

  useEffect(() => {
    if (businessId && availableBusinesses && availableBusinesses?.length > 0) {
      const selected = availableBusinesses?.find(
        (r: any) => r.id === businessId
      )

      if (selected) {
        setSelectedBusiness(selected)
      }
    }
  }, [availableBusinesses, businessId])

  const loadingPage =
    loadingCertificationPeriod ||
    loadingCertifiedBusiness ||
    loadingBusinesses ||
    categoryQuery.isLoading

  const canManage =
    ability.can(
      PermissionCodeAccess.ComplianceManagementSystem_Basic_ManageDocumentSetup,
      'any'
    ) ||
    ability.can(
      PermissionCodeAccess.ComplianceManagementSystem_Basic_ManageCertification,
      'any'
    ) ||
    ability.can(
      PermissionCodeAccess.ComplianceManagementSystem_Basic_ManageFiles,
      'any'
    ) ||
    ability.can(
      PermissionCodeAccess.ComplianceManagementSystem_Basic_Internal,
      'any'
    ) ||
    ability.can(
      PermissionCodeAccess.ComplianceManagementSystem_Basic_External,
      'any'
    ) ||
    ability.can(
      PermissionCodeAccess.ComplianceManagementSystem_Basic_RepositoryAccess,
      'any'
    )

  const canView =
    ability.can(
      PermissionCodeAccess.ComplianceManagementSystem_Basic_ViewAccess,
      'any'
    ) && !canManage

  const readOnly =
    ['Terminated - Out of Business', 'Terminated'].includes(
      selectedBusiness?.status
    ) || canView

  return (
    <>
      <CertificationPeriodContext.Provider value={selectedCertificationPeriod}>
        <Box padding={4} mt={4}>
          <Grid container spacing={3} alignItems="center">
            <Grid item xs={12}>
              <Typography variant="subtitle1">
                {isInternal ? 'Repository' : 'My Repository'}
              </Typography>
            </Grid>
          </Grid>
          <Box
            style={{ width: '100%' }}
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
          >
            {loadingPage && <Loader />}
            <Box display="flex">
              {isInternal && (
                <Grid
                  item
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    verticalAlign: 'center',
                  }}
                >
                  <Box
                    display="flex"
                    alignItems="center"
                    flexDirection="column"
                  >
                    <TextField
                      select
                      id="select-business-status"
                      value={selectedBusinessStatus?.value ?? ''}
                      fullWidth
                      label="Business Status"
                      style={{ minWidth: '250px' }}
                    >
                      <MenuItem
                        value=""
                        onClick={() => {
                          setSelectedBusinessStatus(null)
                        }}
                      >
                        <em>Select business status</em>
                      </MenuItem>

                      {hardcodeData.getBusinessStatus().map((s: any) => {
                        return (
                          <MenuItem
                            key={s.value}
                            value={s.value}
                            onClick={() => {
                              setSelectedBusinessStatus(s)
                            }}
                          >
                            {`${s.label}`}
                          </MenuItem>
                        )
                      })}
                    </TextField>
                  </Box>
                </Grid>
              )}
              {loadingBusinesses && !availableBusinesses ? (
                <Skeleton
                  variant="rect"
                  height={50}
                  width="250px"
                  style={{ marginLeft: 3 }}
                />
              ) : (
                <Grid
                  item
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    verticalAlign: 'center',
                  }}
                >
                  <Box
                    display="flex"
                    alignItems="center"
                    flexDirection="column"
                  >
                    <Box
                      ml={3}
                      display="flex"
                      flexDirection="column"
                      justifyContent="center"
                    >
                      {availableBusinesses && availableBusinesses.length > 1 ? (
                        <TextField
                          select
                          id="select-business"
                          value={selectedBusiness?.id ?? ''}
                          fullWidth
                          label="Business"
                          style={{ minWidth: '250px' }}
                        >
                          <MenuItem value="" disabled={true}>
                            <em>Select business</em>
                          </MenuItem>

                          {availableBusinesses?.map((business: any) => {
                            return (
                              <MenuItem
                                key={business.id}
                                value={business.id}
                                onClick={() => {
                                  handleSelectedBusiness(business)
                                }}
                              >
                                {isInternal
                                  ? `${business.name} - ${business.type}`
                                  : `${business.name}`}
                              </MenuItem>
                            )
                          })}
                        </TextField>
                      ) : (
                        <Typography
                          id="select-business"
                          variant="h2"
                          style={{ fontWeight: 'bold', minWidth: '250px' }}
                        >
                          {selectedBusiness?.name ?? ''}
                        </Typography>
                      )}
                    </Box>
                  </Box>
                </Grid>
              )}

              {loadingCertificationPeriod ? (
                <Skeleton
                  variant="rect"
                  height={50}
                  width="150px"
                  style={{ marginLeft: 3 }}
                />
              ) : (
                availableBusinesses &&
                availableBusinesses.length > 0 && (
                  <Grid
                    item
                    style={{
                      marginLeft: 10,
                      display: 'flex',
                      alignItems: 'center',
                      verticalAlign: 'center',
                    }}
                  >
                    <Box
                      display="flex"
                      flexDirection="column"
                      justifyContent="center"
                    >
                      <Box
                        display="flex"
                        flexDirection="column"
                        justifyContent="center"
                      >
                        <TextField
                          disabled={
                            !selectedBusiness ||
                            selectedBusiness === null ||
                            selectedBusiness === ''
                          }
                          select
                          id="select-certification-date"
                          value={selectedCertificationPeriod.certificationDate}
                          style={{ minWidth: '115px' }}
                          label="Certification Period"
                        >
                          {availablePeriods?.map(
                            (item: ICertificationPeriod) => {
                              return (
                                <MenuItem
                                  key={item.id}
                                  value={item.certificationDate}
                                  onClick={() => {
                                    setSelectedCertificationPeriod(item)
                                  }}
                                >
                                  {item.certificationDate}
                                </MenuItem>
                              )
                            }
                          )}
                        </TextField>
                      </Box>
                    </Box>
                  </Grid>
                )
              )}
            </Box>
          </Box>
          {!categoryQuery.isLoading &&
            !loadingBusinesses &&
            availableBusinesses?.length === 0 && (
              <Box display="flex" mt={isInternal ? 5 : 0}>
                <Typography>No business found for this status.</Typography>
              </Box>
            )}
          <BusinessContext.Provider value={selectedBusiness}>
            <Grid style={{ marginTop: 10 }} item xs={12}>
              {categories && (
                <CategoriesTabs categoryData={categories} origin="repository" />
              )}

              {selectedCertificationPeriod.id !== null &&
                !noCategoriesRegistered &&
                availableBusinesses &&
                availableBusinesses.length > 0 && (
                  <Grid item xs={12}>
                    <Grid container>
                      <Grid item xs={12} style={{ marginTop: '20px' }}>
                        {categoryId && selectedBusiness && categorySelected && (
                          <DocumentCardMain
                            categoryData={categorySelected}
                            businessUsers={[]}
                            filteredUsers={[]}
                            origin="repository"
                            recertification={false}
                            disableItems={readOnly}
                          />
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                )}
            </Grid>
          </BusinessContext.Provider>
        </Box>
      </CertificationPeriodContext.Provider>
    </>
  )
}

export default DocumentRepository
