import { httpClient } from 'src/api/axios/api'
import {
  addBusinessToSOP,
  addSOPToBusiness,
  archiveSOP,
  removeBusinessFromSOP,
  removeSOPFromBusiness,
} from './urls'

export const archiveSOPFile = async (
  id: string,
  reason: string
): Promise<any> => {
  const response = await httpClient.post(archiveSOP, {
    id: Number(id),
    reason,
  })
  return response?.data
}

export const addSOPsToBusiness = async (
  businessId: string,
  sops: number[]
): Promise<any> => {
  const response = await httpClient.post(addSOPToBusiness, {
    businessId,
    sops,
  })
  return response?.data
}

export const removeSOPsFromBusiness = async (
  businessId: string,
  sops: number[]
): Promise<any> => {
  const response = await httpClient.post(removeSOPFromBusiness, {
    businessId,
    sops,
  })
  return response?.data
}

export const addBusinessesToSOP = async (
  businesses: string[],
  sopId: number
): Promise<any> => {
  const response = await httpClient.post(addBusinessToSOP, {
    businesses,
    sopId,
  })
  return response?.data
}

export const removeBusinessesFromSOP = async (
  businesses: string[],
  sopId: number
): Promise<any> => {
  const response = await httpClient.post(removeBusinessFromSOP, {
    businesses,
    sopId,
  })
  return response?.data
}
