import axios from 'axios'
import { useQuery } from 'react-query'
import { BACKEND_URL } from 'src/api/axios/api'
import { GetAccessToken } from 'src/api/axios/helper'
import { getBusinessesQuery } from './business'

const userToken = GetAccessToken()

export function QueryGetBusinesses(clientIdSelected: string | null) {
  return useQuery({
    queryKey: ['businesses'],
    queryFn: async () => {
      return axios({
        url: process.env.REACT_APP_CONTROLPANEL,
        method: 'post',
        headers: {
          Authorization: userToken,
        },
        data: {
          query: `${getBusinessesQuery(clientIdSelected)}`,
        },
      }).then((result: any) => {
        return result.data.data.getBusinesses.businesses || []
      })
    },
  })
}

export function QueryGetCategories() {
  return useQuery({
    queryKey: ['categories'],
    queryFn: async () => {
      return axios({
        method: 'get',
        url: `${BACKEND_URL}/category`,
        headers: {
          Authorization: userToken,
        },
        params: {
          isEnabled: true,
          businessType: 'seller',
          buyerType: '',
        },
      }).then((result: any) => {
        return result.data.categories
      })
    },
  })
}
