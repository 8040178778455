import {
  Box,
  Button,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from '@material-ui/core'
import axios from 'axios'
import React, { useContext, useRef, useState } from 'react'
import { useQuery } from 'react-query'
import { BACKEND_URL, GetAccessToken } from 'src/api/axios/api'
import Loader from 'src/components/Loader'
import { GridColumn as KendoGridColumn } from '@progress/kendo-react-grid'
import { StyledKendoGrid as KendoGrid } from 'src/styles/kendoGridStyle'

import { ColumnMenu } from 'src/components/Filters/HeaderFilter'
import { State } from '@progress/kendo-data-query'
import { GetApp } from '@material-ui/icons'
import { ExcelExport } from '@progress/kendo-react-excel-export'
import { useSnackbar } from 'notistack'
import { notistackOptions } from 'src/configs/notistackOptions'
import {
  renderClickableNumberLink,
  renderDate,
} from 'src/utils/formatKendoColumns'
import { CORPORATE_SOP_DETAIL } from 'src/routes'
import { AbilityContext } from 'src/context/Can'
import { PermissionCodeAccess } from 'src/utils/constants'

const CorporateSOPRepository: React.FC = () => {
  const cardType = 'master'
  const userToken = GetAccessToken()
  const grid = useRef<any>(null)
  const [sopToDownload, setSopToDownload] = useState<any | null>(null)
  const ability = useContext(AbilityContext)
  const canDownloadLatestVersion = ability.can(
    PermissionCodeAccess.ComplianceManagementCorporateSOP_Download,
    'any'
  )

  const notistackSuccess = notistackOptions('success')

  const { refetch: refetchDownloadAll, isRefetching } = useQuery({
    queryKey: ['downloadAllSOP'],
    queryFn: async () => {
      return axios({
        method: 'get',
        url: `${BACKEND_URL}/CorporateSOP/downloadAllSOP`,
        params: { cardType },
        headers: {
          Authorization: userToken,
        },
      }).then((result: any) => {
        return result.data
      })
    },
    onSuccess: (data) => {
      window.location.href = data
    },
    enabled: false,
  })

  useQuery({
    queryKey: ['downloadSOP', sopToDownload],
    cacheTime: 0,
    queryFn: async () => {
      return axios({
        method: 'GET',
        url: `${BACKEND_URL}/corporateSOP/DownloadSOP`,
        params: {
          blobUri: sopToDownload.blobUri,
          name: sopToDownload.name,
        },
        headers: {
          Authorization: userToken,
        },
      }).then((result: any) => {
        setSopToDownload(null)
        window.location.href = result.data || ''
      })
    },
    enabled: sopToDownload !== null,
  })
  const handleDownloadFile = (e: any) => {
    setSopToDownload(e)
  }

  let exportedData: any
  const columns: any[] = [
    {
      field: 'id',
      title: 'SOP ID',
      show: true,
      cell: (props: any) =>
        renderClickableNumberLink(
          props,
          `${CORPORATE_SOP_DETAIL}/${props.dataItem[props.field]}/${cardType}`
        ),
    },
    {
      field: 'name',
      title: 'SOP Name',
      show: true,
    },
    {
      field: 'department',
      title: 'Department',
      show: true,
    },

    {
      field: 'lastUpdatedDateUtc',
      title: 'Publication Date',
      cell: renderDate,
      filter: 'date',
      show: ['master'].includes(cardType),
    },
    {
      field: 'actions',
      title: ' ',
      show: true,
      width: '80px',
      cell: (props: any) => {
        return (
          <>
            <td className="k-command-cell">
              <Tooltip title="Download">
                <span>
                  <IconButton
                    disabled={!canDownloadLatestVersion}
                    color="primary"
                    aria-label="Download"
                    onClick={(e: any) => handleDownloadFile(props.dataItem)}
                  >
                    <GetApp />
                  </IconButton>
                </span>
              </Tooltip>
            </td>
          </>
        )
      },
    },
  ]

  const { enqueueSnackbar } = useSnackbar()
  const notifyError = notistackOptions('error')

  const [gridState, setGridState] = useState<State>({
    skip: 0,
    take: 25,
    filter: undefined,
    sort: undefined,
  })

  const exportData = (dataToExport: any) => {
    if (dataToExport && dataToExport.length > 0) {
      exportedData.save(dataToExport, columns)
    } else {
      enqueueSnackbar('There is no data to be exported', notifyError)
    }
  }

  const getCorporateSOPGrid = useQuery({
    cacheTime: 0,
    queryKey: ['getCorporateSOPGrid', gridState],
    queryFn: async () => {
      return axios({
        method: 'get',
        url: `${BACKEND_URL}/corporateSOP/getCorporateSOPGrid`,
        params: {
          cardType: 'repo',
          pagination: encodeURIComponent(JSON.stringify(gridState)),
        },
        headers: {
          Authorization: userToken,
        },
      }).then((result: any) => {
        return result.data
      })
    },
  })
  const corporateGridData = getCorporateSOPGrid?.data || []

  const handleDownloadAllClick = () => {
    enqueueSnackbar(
      corporateGridData?.files.length === 1
        ? 'Downloading file.'
        : 'Creating zip file. This process may take some time.',
      notistackSuccess
    )
    refetchDownloadAll()
  }

  const loading = getCorporateSOPGrid.isLoading

  return (
    <>
      <Box padding={4} mt={4}>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={12}>
            <Typography variant="subtitle1">
              Corporate SOP Repository
            </Typography>
          </Grid>
        </Grid>

        <Box mt={10}>
          <ExcelExport
            data={corporateGridData?.files}
            fileName="SOP_Repository.xlsx"
            // eslint-disable-next-line no-return-assign
            ref={(exporter) => (exportedData = exporter)}
          >
            <Box>
              <Box display="flex" flexDirection="row" justifyContent="right">
                <Button
                  title="Download All"
                  variant="contained"
                  color="primary"
                  onClick={() => handleDownloadAllClick()}
                  startIcon={<GetApp />}
                  disabled={
                    !corporateGridData ||
                    !canDownloadLatestVersion ||
                    isRefetching
                  }
                >
                  Download all
                </Button>
                <Button
                  style={{ marginLeft: 5 }}
                  title="Export Excel"
                  variant="contained"
                  color="primary"
                  onClick={() => exportData(corporateGridData.files)}
                  disabled={!corporateGridData}
                >
                  Export to Excel
                </Button>
              </Box>

              {loading && <Loader />}

              <Box mt={2}>
                <KendoGrid
                  ref={grid}
                  scrollable="scrollable"
                  data={corporateGridData?.files ?? []}
                  skip={gridState.skip}
                  take={gridState.take}
                  pageSize={gridState.take}
                  filter={gridState.filter}
                  sort={gridState.sort}
                  sortable
                  pageable={{ pageSizes: [5, 10, 25, 50, 100] }}
                  total={corporateGridData?.total ?? 0}
                  onDataStateChange={(e) => {
                    setGridState(e.dataState)
                  }}
                  style={{ minHeight: '200px', maxHeight: '500px' }}
                >
                  {columns.reduce((acc, item, idx) => {
                    const component = [...acc]

                    item.show &&
                      component.push(
                        <KendoGridColumn
                          key={item.field + item.title}
                          field={item.field}
                          title={item.title}
                          cell={item.cell}
                          width={item.width}
                          columnMenu={
                            item.field !== 'actions' ? ColumnMenu : undefined
                          }
                        />
                      )

                    return component
                  }, [])}
                </KendoGrid>
              </Box>
            </Box>
          </ExcelExport>
        </Box>
      </Box>
    </>
  )
}

export default CorporateSOPRepository
