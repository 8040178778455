import {
  Box,
  Button,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from '@material-ui/core'
import { Skeleton } from '@mui/material'
import { enqueueSnackbar } from 'notistack'

import React, { useEffect, useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import { QueryGetBusinesses } from 'src/api/operations/get'
import {
  getActiveSOPs,
  getBusinessesBySOP,
  getSOPByBusiness,
} from 'src/api/operations/get/sop'
import {
  addBusinessesToSOP,
  addSOPsToBusiness,
  removeBusinessesFromSOP,
  removeSOPsFromBusiness,
} from 'src/api/operations/post/sop'
import Loader from 'src/components/Loader'
import TransferList from 'src/components/TransferList/TransferList'
import { notistackOptions } from 'src/configs/notistackOptions'

const SOPAccessControl: React.FC = () => {
  const [option, setOption] = useState('')

  const [sops, setSOPS] = useState<any>()
  const [selectedBusiness, setSelectedBusiness] = useState<any>()
  const [selectedSOP, setSelectedSOP] = useState<any>()

  const [availableItems, setAvailableItems] = useState<any[]>([])
  const [notAvailableItems, setNotAvailableItems] = useState<any[]>([])

  const queryBusiness = QueryGetBusinesses(null)

  const businesses: any[] = queryBusiness?.data || []

  const getSOPS = useQuery({
    queryKey: ['getSOPS'],
    queryFn: async () => {
      const result = await getActiveSOPs()
      setSOPS(result)
    },
  })

  const getSavedBusiness = useQuery({
    enabled:
      option === 'business' &&
      selectedBusiness?.id !== undefined &&
      selectedBusiness?.id !== '',
    queryKey: ['getSOPByBusiness', selectedBusiness],
    queryFn: async () => {
      const result = await getSOPByBusiness(selectedBusiness?.id)

      const nAvailable = sops.filter((f: any) =>
        result?.map((r: any) => r).includes(f.id)
      )
      setNotAvailableItems(nAvailable)

      const available = sops.filter(
        (f: any) => !nAvailable?.map((r: any) => r.id).includes(f.id)
      )
      setAvailableItems(available)
    },
    cacheTime: 0,
  })

  const getSavedSOPS = useQuery({
    enabled:
      option === 'sop' &&
      selectedSOP?.id !== undefined &&
      selectedSOP?.id !== '',
    queryKey: ['getBusinessBySOP', selectedSOP],
    queryFn: async () => {
      const result = await getBusinessesBySOP(selectedSOP?.id)

      const notAvailable = businesses.filter((f: any) =>
        result?.map((r: any) => r.toLowerCase()).includes(f.id.toLowerCase())
      )
      setNotAvailableItems(notAvailable)

      const available = businesses.filter(
        (f: any) =>
          !notAvailable
            ?.map((r: any) => r.id.toLowerCase())
            .includes(f.id.toLowerCase())
      )
      setAvailableItems(available)
    },
    cacheTime: 0,
  })

  const mutationAddSOPToBusiness = useMutation({
    mutationFn: async (data: number[]) => {
      await addSOPsToBusiness(selectedBusiness.id, data).then(() => {
        enqueueSnackbar(
          'SOP access control updated.',
          notistackOptions('success')
        )
      })
    },
  })

  const mutationRemoveSOPFromBusiness = useMutation({
    mutationFn: async (data: number[]) => {
      await removeSOPsFromBusiness(selectedBusiness.id, data).then(() => {
        enqueueSnackbar(
          'SOP access control updated.',
          notistackOptions('success')
        )
      })
    },
  })

  const mutationAddBusinessToSOP = useMutation({
    mutationFn: async (data: any) => {
      await addBusinessesToSOP(data, selectedSOP.id).then(() => {
        enqueueSnackbar(
          'SOP access control updated.',
          notistackOptions('success')
        )
      })
    },
  })

  const mutationRemoveBusinessFromSOP = useMutation({
    mutationFn: async (data: any) => {
      await removeBusinessesFromSOP(data, selectedSOP.id).then(() => {
        enqueueSnackbar(
          'SOP access control updated.',
          notistackOptions('success')
        )
      })
    },
  })

  const handleSendLeftColumn = (data: any[]) => {
    const saved = notAvailableItems.filter(
      (n) => !data.map((d) => d.id).includes(n.id)
    )

    setNotAvailableItems(saved)
  }

  const handleSendRightColumn = (data: any[]) => {
    const saved = notAvailableItems
    const newItems = saved.concat(data)
    setNotAvailableItems(newItems)
  }

  const handleSubmit = (data: any[]) => {
    if (option === 'sop') {
      mutationAddBusinessToSOP.mutate(notAvailableItems.map((f) => f.id))
    } else if (option === 'business') {
      mutationAddSOPToBusiness.mutate(notAvailableItems.map((f) => f.id))
    }
  }

  useEffect(() => {
    if (option === 'sop') {
      setSelectedBusiness(undefined)
    } else if (option === 'business') {
      setSelectedSOP(undefined)
      setAvailableItems([])
      setNotAvailableItems([])
    }
  }, [option])

  const loading =
    getSOPS.isLoading ||
    getSavedBusiness.isLoading ||
    getSavedSOPS.isLoading ||
    (availableItems.length === 0 && notAvailableItems.length === 0)

  return (
    <>
      <Box padding={4} mt={4}>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={12}>
            <Typography variant="subtitle1">SOP Access Control</Typography>
          </Grid>
        </Grid>
        <Box>
          {(mutationAddBusinessToSOP.isLoading ||
            mutationAddSOPToBusiness.isLoading) && <Loader />}
          <Grid container spacing={3}>
            <Grid item xs={12} lg={12} md={12}>
              <Box display="flex">
                <Box>
                  <TextField
                    data-cy="select-option"
                    id="select-option"
                    style={{ minWidth: '250px' }}
                    select
                    label="Option"
                    name="result"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={(e) => setOption(e.target.value)}
                    value={option || null}
                  >
                    <MenuItem value="">
                      <em>Select an option</em>
                    </MenuItem>

                    <MenuItem value="sop" key="sop">
                      SOP
                    </MenuItem>
                    <MenuItem value="business" key="business">
                      Business
                    </MenuItem>
                  </TextField>
                </Box>

                <Box ml={3}>
                  {option === 'business' && (
                    <TextField
                      label="Business"
                      select
                      id="select-business"
                      value={
                        selectedBusiness
                          ? `${selectedBusiness.name} (${selectedBusiness.type})`
                          : ''
                      }
                      style={{ minWidth: '250px' }}
                    >
                      <MenuItem
                        value=""
                        onClick={() => {
                          setSelectedBusiness(null)
                        }}
                      >
                        <em>Select business</em>
                      </MenuItem>

                      {businesses &&
                        businesses?.map((business: any) => {
                          return (
                            <MenuItem
                              key={business.id}
                              value={`${business.name} (${business.type})`}
                              onClick={() => {
                                setSelectedBusiness(business)
                              }}
                            >
                              {`${business.name} (${business.type})`}
                            </MenuItem>
                          )
                        })}
                    </TextField>
                  )}
                  {option === 'sop' && (
                    <TextField
                      label="SOP"
                      select
                      id="select-sop"
                      value={selectedSOP ? `${selectedSOP.name}` : ''}
                      style={{ minWidth: '250px' }}
                    >
                      <MenuItem
                        value=""
                        onClick={() => {
                          setSelectedSOP(null)
                        }}
                      >
                        <em>Select SOP</em>
                      </MenuItem>

                      {sops?.map((sop: any) => {
                        return (
                          <MenuItem
                            key={sop.id}
                            value={`${sop.name}`}
                            onClick={() => {
                              setSelectedSOP(sop)
                            }}
                          >
                            {`${sop.name}`}
                          </MenuItem>
                        )
                      })}
                    </TextField>
                  )}
                </Box>
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  ml={3}
                >
                  <Button
                    id="save-button"
                    data-cy="save-button"
                    onClick={(e: any) => handleSubmit(e)}
                    variant="contained"
                    color="primary"
                    type="button"
                    disabled={
                      (!selectedSOP?.id && !selectedBusiness?.id) ||
                      mutationAddBusinessToSOP.isLoading ||
                      mutationAddSOPToBusiness.isLoading
                    }
                  >
                    Submit
                  </Button>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box mt={5}>
          {option !== '' &&
            (selectedBusiness?.id || selectedSOP?.id) &&
            (loading ? (
              <Box display="flex">
                <Box>
                  {[0, 1, 2, 3, 4].map((item) => (
                    <Box key={item} my={2} p={3}>
                      <Skeleton variant="rectangular" height={20} width={300} />
                    </Box>
                  ))}
                </Box>
                <Box>
                  {[0, 1, 2, 3, 4].map((item) => (
                    <Box key={item} my={2} p={3}>
                      <Skeleton variant="rectangular" height={20} width={300} />
                    </Box>
                  ))}
                </Box>
              </Box>
            ) : (
              <TransferList
                leftData={availableItems}
                rightData={notAvailableItems}
                onSendLeftColumn={handleSendLeftColumn}
                onSendRightColumn={handleSendRightColumn}
                loading={loading}
              />
            ))}
        </Box>
      </Box>
    </>
  )
}

export default SOPAccessControl
