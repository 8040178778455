/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext } from 'react'
import {
  Box,
  Button,
  Card,
  CardHeader,
  Grid,
  IconButton,
  MenuItem,
  TextField,
  Typography,
} from '@material-ui/core'
import DescriptionIcon from '@material-ui/icons/Description'
import DeleteIcon from '@material-ui/icons/Delete'
import { maskPhoneNumber } from 'src/utils/masker'
import { notistackOptions } from 'src/configs/notistackOptions'
import { useSnackbar } from 'notistack'
import { AuthContext } from 'src/context/AuthenticationContext'
import { GetAccessToken } from 'src/api/axios/helper'
import axios from 'axios'
import { BACKEND_URL } from 'src/api/axios/api'
import { FilesToUpload, isUkCountry } from 'src/utils/common'
import { BusinessContext } from 'src/context/BusinessContext'
import { GlobalContext } from 'src/context/GlobalContext'
import { MaxFileSize } from 'src/utils/constants'
import { IUser } from 'src/api/models/Common'
import { useMutation } from 'react-query'
import { createUsers } from 'src/api/operations/post'

import { UploadItem } from '../UploadDragDrop/styles'
import UploadDragDrop from '../UploadDragDrop'

const MAX_SIZE_FILE = MaxFileSize

interface IComplianceLegalRegulatory {
  handleChange: (name: any, value: any, setValue: boolean) => void
  inputDataForm: any
  readOnly: boolean
  selectedItem: any
  status: string
  filesUploaded: FilesToUpload[] | null
  setSupportingDocument: (file: FilesToUpload) => void
  handleRemoveFile: (section: string) => void
  dirty: boolean
}

const ComplianceLegalRegulatory: React.FC<IComplianceLegalRegulatory> = ({
  handleChange,
  inputDataForm,
  readOnly,
  selectedItem,
  filesUploaded,
  status,
  setSupportingDocument,
  handleRemoveFile,
  dirty,
}: IComplianceLegalRegulatory) => {
  const files = filesUploaded
  const { userPermissions, profileClient } = useContext(AuthContext)
  const context = useContext<any>(BusinessContext)
  const { loading, setLoading } = useContext(GlobalContext)

  const userToken = GetAccessToken()
  const isInternal = userPermissions.type.toLowerCase() === 'internal'
  const { enqueueSnackbar } = useSnackbar()
  const handleFileRejected = () => {
    enqueueSnackbar('The file has been rejected.', notistackOptions('warning'))
  }

  const handleDroppedFiles = (acceptedFiles: File, section: string) => {
    if (acceptedFiles !== null && acceptedFiles) {
      const newFile = {
        section,
        file: acceptedFiles,
      }

      setSupportingDocument(newFile)
      handleChange(section, acceptedFiles.name, true)
    }
  }

  const isUk = isUkCountry(
    profileClient?.Country || process.env.REACT_APP_COUNTRY
  )
  const country = profileClient?.Country || process.env.REACT_APP_COUNTRY

  const organizationVerbiage = isUk ? 'organisation' : 'organization'

  const handleUploadFileRemove = (section: string) => {
    if (filesUploaded) {
      handleRemoveFile(section)
      if (selectedItem !== null && selectedItem.supportingDocuments !== null) {
        const jsonData = JSON.parse(selectedItem.formData)
        const formMapped: any = new Map(Object.entries(jsonData)).get('0')
        const item = formMapped[section]

        handleChange(section, item, false)
      } else {
        handleChange(section, null, false)
      }
    }
  }

  const handleDownloadSupportingDocument = (section: string) => {
    setLoading(true)
    axios({
      method: 'get',
      url: `${BACKEND_URL}/fillableDocument/GetSupportingDocument`,
      headers: {
        Authorization: userToken,
      },
      params: {
        fileBlobUri: selectedItem.supportingDocuments.find(
          (f: any) => String(f.section).toLowerCase() === section.toLowerCase()
        )?.blobUri,
        supportingFileName: `${context.name}_${section}`,
      },
    })
      .then((result: any) => {
        window.location.href = result.data
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const createComplaintUsers = () => {
    const officerName =
      inputDataForm.values.resolutionComplaint1fullName.split(' ')

    const managerName =
      inputDataForm.values.resolutionComplaint2FullName.split(' ')

    if (officerName.length === 1 || managerName.length === 1) {
      enqueueSnackbar('Insert full name to users ', notistackOptions('warning'))
    }

    const officer: IUser = {
      DisplayName: inputDataForm.values.resolutionComplaint1fullName,
      EmailAddress: inputDataForm.values.resolutionComplaint1EmailAddress,
      FirstName: officerName[0],
      LastName: officerName[officerName.length - 1],
      VoicePhoneNumber: inputDataForm.values.resolutionComplaint1Telephone,
      UserType: 'udd-user',
      Status: 'enabled',
      Title: inputDataForm.values.resolutionComplaint1Title,
    }

    const manager: IUser = {
      DisplayName: inputDataForm.values.resolutionComplaint2FullName,
      EmailAddress: inputDataForm.values.resolutionComplaint2emailAddress,
      FirstName: managerName[0],
      LastName: managerName[managerName.length - 1],
      VoicePhoneNumber: inputDataForm.values.resolutionComplaint2telephone,
      UserType: 'udd-user',
      Title: inputDataForm.values.resolutionComplaint2title,
      Status: 'enabled',
    }

    const users: IUser[] = []

    users.push(officer)
    users.push(manager)

    mutationCreateUsers.mutate(JSON.stringify(users))
  }

  const createComplianceUsers = () => {
    const officerName =
      inputDataForm.values.complianceOfficerfullName.split(' ')

    const managerName = inputDataForm.values.managerFullName.split(' ')

    if (officerName.length === 1 || managerName.length === 1) {
      enqueueSnackbar('Insert full name to users ', notistackOptions('warning'))
    }

    const officer: IUser = {
      DisplayName: inputDataForm.values.complianceOfficerfullName,
      EmailAddress: inputDataForm.values.complianceOfficerEmailAddress,
      FirstName: officerName[0],
      LastName: officerName[officerName.length - 1],
      VoicePhoneNumber: inputDataForm.values.complianceOfficerTelephone,
      Status: 'enabled',
      UserType: 'udd-user',
      Title: inputDataForm.values.complianceOfficerTitle,
    }

    const manager: IUser = {
      DisplayName: inputDataForm.values.managerFullName,
      EmailAddress: inputDataForm.values.manageremailAddress,
      FirstName: managerName[0],
      LastName: managerName[managerName.length - 1],
      VoicePhoneNumber: inputDataForm.values.managertelephone,
      UserType: 'udd-user',
      Status: 'enabled',
      Title: inputDataForm.values.managertitle,
    }

    const users: IUser[] = []

    users.push(officer)
    users.push(manager)

    mutationCreateUsers.mutate(JSON.stringify(users))
  }

  const mutationCreateUsers = useMutation({
    mutationFn: async (users: string) => {
      const result = await createUsers(context.id, users)

      const rArray: string[] = result.split(';')

      const validations = rArray.map((r: any, index: number) => {
        return (
          <a key={`${index}${r}`}>
            <a key={index}>{r}</a>
            {index < rArray.length - 1 && <br />}
          </a>
        )
      })

      enqueueSnackbar(<a>{validations}</a>, notistackOptions('success'))
    },
  })

  return (
    <>
      <Card elevation={1} style={{ width: '100%' }}>
        <CardHeader
          title="Compliance Officer/Manager"
          subheader={
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
            >
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
              >
                Who are the individuals responsible for compliance
                {!isUk ? ' with Federal & State regulations' : ''}?
              </Box>
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
              >
                <Button
                  id="btnUsersCompliance"
                  data-cy="btnUsersCompliance"
                  variant="contained"
                  color="primary"
                  disabled={
                    status !== 'Accepted' ||
                    dirty ||
                    mutationCreateUsers.isLoading
                  }
                  onClick={createComplianceUsers}
                >
                  Create Users
                </Button>
              </Box>
            </Box>
          }
        />
        <Grid
          container
          direction="row"
          style={{ margin: '6px 6px 6px 6px', width: '800px' }}
          spacing={3}
        >
          <Grid item xs={12} md={4}>
            <TextField
              disabled={readOnly}
              fullWidth
              data-cy="dialog-input-field-fullname-complianceOfficer"
              label="Full Name"
              name="complianceOfficerfullName"
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                maxLength: 100,
              }}
              onChange={({ target: { value, name } }) => {
                handleChange(name, value, false)
              }}
              value={inputDataForm.values.complianceOfficerfullName}
              error={!!inputDataForm.errors.complianceOfficerfullName}
              helperText={inputDataForm.errors.complianceOfficerfullName}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              disabled={readOnly}
              fullWidth
              data-cy="dialog-input-field-title-complianceOfficer"
              label="Title"
              name="complianceOfficerTitle"
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                maxLength: 100,
              }}
              onChange={({ target: { value, name } }) => {
                handleChange(name, value, false)
              }}
              value={inputDataForm.values.complianceOfficerTitle}
              error={!!inputDataForm.errors.complianceOfficerTitle}
              helperText={inputDataForm.errors.complianceOfficerTitle}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              disabled={readOnly}
              fullWidth
              data-cy="dialog-input-field-yearsOfExperience-complianceOfficer"
              label="Years of Experience"
              type="number"
              name="complianceOfficerYearsOfExperience"
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                min: 0,
              }}
              onChange={({ target: { value, name } }) => {
                if (isNaN(value as any) || Number(value) > 99) return
                handleChange(
                  name,
                  Number(value).toString().substring(0, 2),
                  false
                )
              }}
              value={
                inputDataForm.values.complianceOfficerYearsOfExperience || ''
              }
              error={!!inputDataForm.errors.complianceOfficerYearsOfExperience}
              helperText={
                inputDataForm.errors.complianceOfficerYearsOfExperience
              }
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              disabled={readOnly}
              fullWidth
              type="number"
              data-cy="dialog-input-field-yearsWithYourCompany-complianceOfficer"
              label="How many years with your company?"
              name="complianceOfficerYearsWithYourCompany"
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                min: 0,
              }}
              onChange={({ target: { value, name } }) => {
                if (isNaN(value as any) || Number(value) > 99) return
                handleChange(
                  name,
                  Number(value).toString().substring(0, 2),
                  false
                )
              }}
              value={
                inputDataForm.values.complianceOfficerYearsWithYourCompany || ''
              }
              error={
                !!inputDataForm.errors.complianceOfficerYearsWithYourCompany
              }
              helperText={
                inputDataForm.errors.complianceOfficerYearsWithYourCompany
              }
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              disabled={readOnly}
              fullWidth
              data-cy="dialog-input-field-telephone-complianceOfficer"
              label="Telephone"
              name="complianceOfficerTelephone"
              InputLabelProps={{
                shrink: true,
              }}
              onChange={({ target: { value, name } }) => {
                const masked = maskPhoneNumber(value, country)
                handleChange(name, masked, false)
              }}
              value={inputDataForm.values.complianceOfficerTelephone}
              error={!!inputDataForm.errors.complianceOfficerTelephone}
              helperText={inputDataForm.errors.complianceOfficerTelephone}
              placeholder={`${!isUk ? '(000) 000-0000' : ''}`}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              disabled={readOnly}
              fullWidth
              data-cy="dialog-input-field-emailAddress-complianceOfficer"
              label="Email Address"
              name="complianceOfficerEmailAddress"
              InputLabelProps={{
                shrink: true,
              }}
              onChange={({ target: { value, name } }) => {
                handleChange(name, value, false)
              }}
              value={inputDataForm.values.complianceOfficerEmailAddress}
              error={!!inputDataForm.errors.complianceOfficerEmailAddress}
              helperText={inputDataForm.errors.complianceOfficerEmailAddress}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              disabled={readOnly}
              fullWidth
              data-cy="dialog-input-field-fullname-manager"
              label="Full Name"
              name="managerFullName"
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                maxLength: 100,
              }}
              onChange={({ target: { value, name } }) => {
                handleChange(name, value, false)
              }}
              value={inputDataForm.values.managerFullName}
              error={!!inputDataForm.errors.managerFullName}
              helperText={inputDataForm.errors.managerFullName}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              disabled={readOnly}
              fullWidth
              data-cy="dialog-input-field-title-manager"
              label="Title"
              name="managertitle"
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                maxLength: 100,
              }}
              onChange={({ target: { value, name } }) => {
                handleChange(name, value, false)
              }}
              value={inputDataForm.values.managertitle}
              error={!!inputDataForm.errors.managertitle}
              helperText={inputDataForm.errors.managertitle}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              disabled={readOnly}
              fullWidth
              data-cy="dialog-input-field-yearsOfExperience-manager"
              label="Years of Experience"
              name="manageryearsOfExperience"
              type="number"
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                min: 0,
              }}
              onChange={({ target: { value, name } }) => {
                if (isNaN(value as any) || Number(value) > 99) return
                handleChange(
                  name,
                  Number(value).toString().substring(0, 2),
                  false
                )
              }}
              value={inputDataForm.values.manageryearsOfExperience || ''}
              error={!!inputDataForm.errors.manageryearsOfExperience}
              helperText={inputDataForm.errors.manageryearsOfExperience}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              disabled={readOnly}
              fullWidth
              type="number"
              data-cy="dialog-input-field-yearsWithYourCompany-manager"
              label="How many years with your company?"
              name="manageryearsWithYourCompany"
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                min: 0,
              }}
              onChange={({ target: { value, name } }) => {
                if (isNaN(value as any) || Number(value) > 99) return
                handleChange(
                  name,
                  Number(value).toString().substring(0, 2),
                  false
                )
              }}
              value={inputDataForm.values.manageryearsWithYourCompany || ''}
              error={!!inputDataForm.errors.manageryearsWithYourCompany}
              helperText={inputDataForm.errors.manageryearsWithYourCompany}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              disabled={readOnly}
              fullWidth
              data-cy="dialog-input-field-telephone-manager"
              label="Telephone"
              name="managertelephone"
              InputLabelProps={{
                shrink: true,
              }}
              onChange={({ target: { value, name } }) => {
                const masked = maskPhoneNumber(value, country)
                handleChange(name, masked, false)
              }}
              value={inputDataForm.values.managertelephone}
              error={!!inputDataForm.errors.managertelephone}
              helperText={inputDataForm.errors.managertelephone}
              placeholder={`${!isUk ? '(000) 000-0000' : ''}`}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              disabled={readOnly}
              fullWidth
              data-cy="dialog-input-field-emailAddress-manager"
              label="Email Address"
              name="manageremailAddress"
              InputLabelProps={{
                shrink: true,
              }}
              onChange={({ target: { value, name } }) => {
                handleChange(name, value, false)
              }}
              value={inputDataForm.values.manageremailAddress}
              error={!!inputDataForm.errors.manageremailAddress}
              helperText={inputDataForm.errors.manageremailAddress}
            />
          </Grid>
        </Grid>
      </Card>
      <Card elevation={1} style={{ width: '100%', marginTop: '16px' }}>
        <CardHeader
          title="Complaint Process & Procedures"
          subheader={
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
            >
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
              >
                Who are the individuals responsible for the resolution of
                complaints? (Minimum of 2 Contacts Required)
              </Box>
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
              >
                <Button
                  id="btnUsersComplaint"
                  data-cy="btnUsersComplaint"
                  variant="contained"
                  color="primary"
                  disabled={
                    status !== 'Accepted' ||
                    dirty ||
                    mutationCreateUsers.isLoading
                  }
                  onClick={createComplaintUsers}
                >
                  Create Users
                </Button>
              </Box>
            </Box>
          }
        />
        <Grid
          container
          direction="row"
          style={{ margin: '6px 6px 6px 6px', width: '800px' }}
          spacing={3}
        >
          <Grid item xs={12} md={4}>
            <TextField
              disabled={readOnly}
              fullWidth
              data-cy="dialog-input-field-fullname-resolutionComplaint1"
              label="Full Name"
              name="resolutionComplaint1fullName"
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                maxLength: 100,
              }}
              onChange={({ target: { value, name } }) => {
                handleChange(name, value, false)
              }}
              value={inputDataForm.values.resolutionComplaint1fullName}
              error={!!inputDataForm.errors.resolutionComplaint1fullName}
              helperText={inputDataForm.errors.resolutionComplaint1fullName}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              disabled={readOnly}
              fullWidth
              data-cy="dialog-input-field-title-resolutionComplaint1"
              label="Title"
              name="resolutionComplaint1Title"
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                maxLength: 100,
              }}
              onChange={({ target: { value, name } }) => {
                handleChange(name, value, false)
              }}
              value={inputDataForm.values.resolutionComplaint1Title}
              error={!!inputDataForm.errors.resolutionComplaint1Title}
              helperText={inputDataForm.errors.resolutionComplaint1Title}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              disabled={readOnly}
              fullWidth
              type="number"
              data-cy="dialog-input-field-yearsOfExperience-resolutionComplaint1"
              label="Years of Experience"
              name="resolutionComplaint1YearsOfExperience"
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                min: 0,
              }}
              onChange={({ target: { value, name } }) => {
                if (isNaN(value as any) || Number(value) > 99) return
                handleChange(
                  name,
                  Number(value).toString().substring(0, 2),
                  false
                )
              }}
              value={
                inputDataForm.values.resolutionComplaint1YearsOfExperience || ''
              }
              error={
                !!inputDataForm.errors.resolutionComplaint1YearsOfExperience
              }
              helperText={
                inputDataForm.errors.resolutionComplaint1YearsOfExperience
              }
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              disabled={readOnly}
              type="number"
              fullWidth
              data-cy="dialog-input-field-yearsWithYourCompany-resolutionComplaint1"
              label="How many years with your company?"
              name="resolutionComplaint1YearsWithYourCompany"
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                min: 0,
              }}
              onChange={({ target: { value, name } }) => {
                if (isNaN(value as any) || Number(value) > 99) return
                handleChange(
                  name,
                  Number(value).toString().substring(0, 2),
                  false
                )
              }}
              value={
                inputDataForm.values.resolutionComplaint1YearsWithYourCompany ||
                ''
              }
              error={
                !!inputDataForm.errors.resolutionComplaint1YearsWithYourCompany
              }
              helperText={
                inputDataForm.errors.resolutionComplaint1YearsWithYourCompany
              }
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              disabled={readOnly}
              fullWidth
              data-cy="dialog-input-field-telephone-resolutionComplaint1"
              label="Telephone"
              name="resolutionComplaint1Telephone"
              InputLabelProps={{
                shrink: true,
              }}
              onChange={({ target: { value, name } }) => {
                const masked = maskPhoneNumber(value, country)
                handleChange(name, masked, false)
              }}
              value={inputDataForm.values.resolutionComplaint1Telephone}
              error={!!inputDataForm.errors.resolutionComplaint1Telephone}
              helperText={inputDataForm.errors.resolutionComplaint1Telephone}
              placeholder={`${!isUk ? '(000) 000-0000' : ''}`}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              disabled={readOnly}
              fullWidth
              data-cy="dialog-input-field-emailAddress-resolutionComplaint1"
              label="Email Address"
              name="resolutionComplaint1EmailAddress"
              InputLabelProps={{
                shrink: true,
              }}
              onChange={({ target: { value, name } }) => {
                handleChange(name, value, false)
              }}
              value={inputDataForm.values.resolutionComplaint1EmailAddress}
              error={!!inputDataForm.errors.resolutionComplaint1EmailAddress}
              helperText={inputDataForm.errors.resolutionComplaint1EmailAddress}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              disabled={readOnly}
              fullWidth
              data-cy="dialog-input-field-fullname-resolutionComplaint2"
              label="Full Name"
              name="resolutionComplaint2FullName"
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                maxLength: 100,
              }}
              onChange={({ target: { value, name } }) => {
                handleChange(name, value, false)
              }}
              value={inputDataForm.values.resolutionComplaint2FullName}
              error={!!inputDataForm.errors.resolutionComplaint2FullName}
              helperText={inputDataForm.errors.resolutionComplaint2FullName}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              disabled={readOnly}
              fullWidth
              data-cy="dialog-input-field-title-resolutionComplaint2"
              label="Title"
              name="resolutionComplaint2title"
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                maxLength: 100,
              }}
              onChange={({ target: { value, name } }) => {
                handleChange(name, value, false)
              }}
              value={inputDataForm.values.resolutionComplaint2title}
              error={!!inputDataForm.errors.resolutionComplaint2title}
              helperText={inputDataForm.errors.resolutionComplaint2title}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              disabled={readOnly}
              type="number"
              fullWidth
              data-cy="dialog-input-field-yearsOfExperience-resolutionComplaint2"
              label="Years of Experience"
              name="resolutionComplaint2yearsOfExperience"
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                min: 0,
              }}
              onChange={({ target: { value, name } }) => {
                if (isNaN(value as any) || Number(value) > 99) return
                handleChange(
                  name,
                  Number(value).toString().substring(0, 2),
                  false
                )
              }}
              value={
                inputDataForm.values.resolutionComplaint2yearsOfExperience || ''
              }
              error={
                !!inputDataForm.errors.resolutionComplaint2yearsOfExperience
              }
              helperText={
                inputDataForm.errors.resolutionComplaint2yearsOfExperience
              }
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              disabled={readOnly}
              type="number"
              fullWidth
              data-cy="dialog-input-field-yearsWithYourCompany-resolutionComplaint2"
              label="How many years with your company?"
              name="resolutionComplaint2yearsWithYourCompany"
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                min: 0,
              }}
              onChange={({ target: { value, name } }) => {
                if (isNaN(value as any) || Number(value) > 99) return
                handleChange(
                  name,
                  Number(value).toString().substring(0, 2),
                  false
                )
              }}
              value={
                inputDataForm.values.resolutionComplaint2yearsWithYourCompany ||
                ''
              }
              error={
                !!inputDataForm.errors.resolutionComplaint2yearsWithYourCompany
              }
              helperText={
                inputDataForm.errors.resolutionComplaint2yearsWithYourCompany
              }
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              disabled={readOnly}
              fullWidth
              data-cy="dialog-input-field-telephone-resolutionComplaint2"
              label="Telephone"
              name="resolutionComplaint2telephone"
              InputLabelProps={{
                shrink: true,
              }}
              onChange={({ target: { value, name } }) => {
                const masked = maskPhoneNumber(value, country)
                handleChange(name, masked, false)
              }}
              value={inputDataForm.values.resolutionComplaint2telephone}
              error={!!inputDataForm.errors.resolutionComplaint2telephone}
              helperText={inputDataForm.errors.resolutionComplaint2telephone}
              placeholder={`${!isUk ? '(000) 000-0000' : ''}`}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              disabled={readOnly}
              fullWidth
              data-cy="dialog-input-field-emailAddress-resolutionComplaint2"
              label="Email Address"
              name="resolutionComplaint2emailAddress"
              InputLabelProps={{
                shrink: true,
              }}
              onChange={({ target: { value, name } }) => {
                handleChange(name, value, false)
              }}
              value={inputDataForm.values.resolutionComplaint2emailAddress}
              error={!!inputDataForm.errors.resolutionComplaint2emailAddress}
              helperText={inputDataForm.errors.resolutionComplaint2emailAddress}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <Box display="flex" flexDirection="column">
              <Box>
                {isInternal && !inputDataForm.values.complaintHistory && (
                  <Typography variant="caption" color="textSecondary">
                    There is no file uploaded for this section.
                  </Typography>
                )}

                {inputDataForm.values.complaintProcess &&
                  (!files ||
                    files === null ||
                    files?.length === 0 ||
                    !files.find(
                      (f: any) => f.section === 'complaintProcess'
                    )) && (
                    <Box
                      display="flex"
                      flexDirection="row"
                      style={{ width: '100%' }}
                    >
                      <TextField
                        disabled={readOnly}
                        fullWidth
                        data-cy="dialog-input-field-complaintProcess"
                        name="complaintProcess"
                        value={
                          inputDataForm.values.complaintProcess.length > 80
                            ? `${inputDataForm.values.complaintProcess.substring(
                                0,
                                75
                              )}...`
                            : inputDataForm.values.complaintProcess
                        }
                        error={!!inputDataForm.errors.complaintProcess}
                        helperText={inputDataForm.errors.complaintProcess}
                        InputProps={{ readOnly: true }}
                      />

                      <Button
                        style={{ marginLeft: 3 }}
                        size="small"
                        data-cy="download"
                        variant="contained"
                        color="primary"
                        disabled={loading}
                        onClick={() => {
                          handleDownloadSupportingDocument('ComplaintProcess')
                        }}
                      >
                        Download
                      </Button>
                    </Box>
                  )}
                {!isInternal &&
                files &&
                files !== null &&
                files?.length > 0 &&
                files.find((f: any) => f.section === 'complaintProcess') ? (
                  <UploadItem>
                    <Grid container>
                      <Grid item xs={12} md={8}>
                        {files
                          .filter((t: any) => t.section === 'complaintProcess')
                          .map((file: any, idx: number) => {
                            return (
                              <div
                                className="upload-item-info"
                                key={`div_${idx}`}
                              >
                                <DescriptionIcon
                                  key={`DescriptionIcon_${idx}`}
                                  fontSize="small"
                                  color="primary"
                                  className="upload-item-icon"
                                />
                                <Typography
                                  variant="body2"
                                  color="textSecondary"
                                  component="span"
                                  key={`Typography_${file.file.name}`}
                                >
                                  {file.file.name.length > 50
                                    ? `${file.file.name.substring(0, 45)}...`
                                    : file.file.name}
                                </Typography>
                                <IconButton
                                  size="small"
                                  key={`Button_${idx}`}
                                  onClick={() => {
                                    handleUploadFileRemove('complaintProcess')
                                  }}
                                >
                                  <DeleteIcon
                                    key={`DeleteButton_${idx}`}
                                    className="upload-item-icon"
                                    fontSize="small"
                                  />
                                </IconButton>
                              </div>
                            )
                          })}
                      </Grid>
                    </Grid>
                  </UploadItem>
                ) : (
                  !isInternal &&
                  !readOnly && (
                    <UploadDragDrop
                      linkText={
                        inputDataForm.values.complaintProcess
                          ? 'Replace File'
                          : 'Upload File'
                      }
                      text=" - PDF files only (max. 200mb)"
                      size="small"
                      multiple={false}
                      onDrop={(acceptedFiles) => {
                        handleDroppedFiles(acceptedFiles[0], 'complaintProcess')
                      }}
                      maxSize={MAX_SIZE_FILE}
                      onDropRejected={handleFileRejected}
                      accept="application/pdf"
                    />
                  )
                )}
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Card>
      <Card elevation={1} style={{ width: '100%', marginTop: '16px' }}>
        <CardHeader title="Complaint History" />
        <Grid
          container
          direction="row"
          style={{
            margin: '6px 6px 6px 6px',
            width: '800px',
            marginRight: '20px',
          }}
          spacing={3}
        >
          <Grid item xs={12} md={12}>
            <TextField
              disabled={readOnly}
              type="number"
              fullWidth
              data-cy="dialog-input-field-qtyConsumerComplaints"
              label={`How many consumer complaints has your ${organizationVerbiage} received in the last 3 years?`}
              name="qtyConsumerComplaints"
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                min: 0,
              }}
              onChange={({ target: { value, name } }) => {
                if (isNaN(value as any) || Number(value) > 999) return
                handleChange(
                  name,
                  Number(value).toString().substring(0, 3),
                  false
                )
              }}
              value={inputDataForm.values.qtyConsumerComplaints || ''}
              error={!!inputDataForm.errors.qtyConsumerComplaints}
              helperText={inputDataForm.errors.qtyConsumerComplaints}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <TextField
              disabled={readOnly}
              fullWidth
              multiline
              variant="outlined"
              rows={4}
              data-cy="dialog-input-field-notes"
              label="Notes & Comments (if applicable)"
              name="notes"
              InputLabelProps={{
                shrink: true,
              }}
              onChange={({ target: { value, name } }) => {
                handleChange(name, value, false)
              }}
              value={inputDataForm.values.notes}
              error={!!inputDataForm.errors.notes}
              helperText={inputDataForm.errors.notes}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <Box display="flex" flexDirection="column">
              <Box>
                {isInternal && !inputDataForm.values.complaintHistory && (
                  <Typography variant="caption" color="textSecondary">
                    There is no file uploaded for this section.
                  </Typography>
                )}

                {inputDataForm.values.complaintHistory &&
                  (!files ||
                    files === null ||
                    files?.length === 0 ||
                    !files.find(
                      (f: any) => f.section === 'complaintHistory'
                    )) && (
                    <Box
                      display="flex"
                      flexDirection="row"
                      style={{ width: '100%' }}
                    >
                      <TextField
                        disabled={readOnly}
                        fullWidth
                        data-cy="dialog-input-field-complaintHistory"
                        name="complaintHistory"
                        value={
                          inputDataForm.values.complaintHistory.length > 80
                            ? `${inputDataForm.values.complaintHistory.substring(
                                0,
                                75
                              )}...`
                            : inputDataForm.values.complaintHistory
                        }
                        error={!!inputDataForm.errors.complaintHistory}
                        helperText={inputDataForm.errors.complaintHistory}
                        InputProps={{ readOnly: true }}
                      />

                      <Button
                        style={{ marginLeft: 3 }}
                        size="small"
                        data-cy="download"
                        variant="contained"
                        color="primary"
                        disabled={loading}
                        onClick={() => {
                          handleDownloadSupportingDocument('ComplaintHistory')
                        }}
                      >
                        Download
                      </Button>
                    </Box>
                  )}
                {files &&
                files !== null &&
                files?.length > 0 &&
                files.find((f: any) => f.section === 'complaintHistory') ? (
                  <UploadItem>
                    <Grid container>
                      <Grid item xs={12} md={8}>
                        {files
                          .filter((t: any) => t.section === 'complaintHistory')
                          .map((file: any, idx: number) => {
                            return (
                              <div
                                className="upload-item-info"
                                key={`div_${idx}`}
                              >
                                <DescriptionIcon
                                  key={`DescriptionIcon_${idx}`}
                                  fontSize="small"
                                  color="primary"
                                  className="upload-item-icon"
                                />
                                <Typography
                                  variant="body2"
                                  color="textSecondary"
                                  component="span"
                                  key={`Typography_${file.file.name}`}
                                >
                                  {file.file.name.length > 50
                                    ? `${file.file.name.substring(0, 45)}...`
                                    : file.file.name}
                                </Typography>
                                <IconButton
                                  size="small"
                                  key={`Button_${idx}`}
                                  onClick={() => {
                                    handleUploadFileRemove('complaintHistory')
                                  }}
                                >
                                  <DeleteIcon
                                    key={`DeleteButton_${idx}`}
                                    className="upload-item-icon"
                                    fontSize="small"
                                  />
                                </IconButton>
                              </div>
                            )
                          })}
                      </Grid>
                    </Grid>
                  </UploadItem>
                ) : (
                  !isInternal &&
                  !readOnly && (
                    <UploadDragDrop
                      linkText={
                        inputDataForm.values.complaintHistory
                          ? 'Replace File'
                          : 'Upload File'
                      }
                      text=" - PDF files only (max. 200mb)"
                      size="small"
                      multiple={false}
                      onDrop={(acceptedFiles) => {
                        handleDroppedFiles(acceptedFiles[0], 'complaintHistory')
                      }}
                      maxSize={MAX_SIZE_FILE}
                      onDropRejected={handleFileRejected}
                      accept="application/pdf"
                    />
                  )
                )}
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Card>
      <Card elevation={1} style={{ width: '100%', marginTop: '16px' }}>
        <CardHeader title="Regulatory History" />
        <Grid
          container
          direction="row"
          style={{
            margin: '6px 6px 6px 6px',
            width: '800px',
            marginRight: '20px',
          }}
          spacing={3}
        >
          <Grid item xs={12} md={12}>
            <TextField
              disabled={readOnly}
              data-cy="option-regulatory-enforcement-dropdown"
              id="optionRegulatoryEnforcement"
              select
              label={`Has your ${organizationVerbiage} been subject to regulatory enforcement in the last 3 years?`}
              style={{ width: '100%' }}
              name="optionRegulatoryEnforcement"
              onChange={({ target: { value, name } }) => {
                handleChange(name, value, true)
              }}
              InputLabelProps={{
                shrink: true,
              }}
              value={inputDataForm.values.optionRegulatoryEnforcement}
              error={!!inputDataForm.errors.optionRegulatoryEnforcement}
              helperText={inputDataForm.errors.optionRegulatoryEnforcement}
            >
              <MenuItem value="Yes">Yes</MenuItem>
              <MenuItem value="No">No</MenuItem>
              <MenuItem value="N/A">N/A</MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={12} md={12}>
            <TextField
              disabled={readOnly}
              fullWidth
              multiline
              variant="outlined"
              rows={6}
              data-cy="input-regulatory-enforcement"
              label="Provide details for all regulatory enforcement activity that includes date of action, summary of action, outcome and
              status:"
              name="regulatoryEnforcementDetail"
              InputLabelProps={{
                shrink: true,
              }}
              onChange={({ target: { value, name } }) => {
                handleChange(name, value, false)
              }}
              value={inputDataForm.values.regulatoryEnforcementDetail}
              error={!!inputDataForm.errors.regulatoryEnforcementDetail}
              helperText={inputDataForm.errors.regulatoryEnforcementDetail}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <Box display="flex" flexDirection="column">
              <Box>
                {isInternal && !inputDataForm.values.regulatoryHistory && (
                  <Typography variant="caption" color="textSecondary">
                    There is no file uploaded for this section.
                  </Typography>
                )}

                {inputDataForm.values.regulatoryHistory &&
                  (!files ||
                    files === null ||
                    files?.length === 0 ||
                    !files.find(
                      (f: any) => f.section === 'regulatoryHistory'
                    )) && (
                    <Box
                      display="flex"
                      flexDirection="row"
                      style={{ width: '100%' }}
                    >
                      <TextField
                        disabled={readOnly}
                        fullWidth
                        data-cy="dialog-input-field-regulatoryHistory"
                        name="regulatoryHistory"
                        value={
                          inputDataForm.values.regulatoryHistory.length > 80
                            ? `${inputDataForm.values.regulatoryHistory.substring(
                                0,
                                75
                              )}...`
                            : inputDataForm.values.regulatoryHistory
                        }
                        error={!!inputDataForm.errors.regulatoryHistory}
                        helperText={inputDataForm.errors.regulatoryHistory}
                        InputProps={{ readOnly: true }}
                      />

                      <Button
                        style={{ marginLeft: 3 }}
                        size="small"
                        data-cy="download"
                        variant="contained"
                        color="primary"
                        disabled={loading}
                        onClick={() => {
                          handleDownloadSupportingDocument('RegulatoryHistory')
                        }}
                      >
                        Download
                      </Button>
                    </Box>
                  )}
                {!isInternal &&
                files &&
                files.length > 0 &&
                files.find((f: any) => f.section === 'regulatoryHistory') ? (
                  <UploadItem>
                    <Grid container>
                      <Grid item xs={12} md={8}>
                        {files
                          .filter((t: any) => t.section === 'regulatoryHistory')
                          .map((file: any, idx: number) => {
                            return (
                              <div
                                className="upload-item-info"
                                key={`div_${idx}`}
                              >
                                <DescriptionIcon
                                  key={`DescriptionIcon_${idx}`}
                                  fontSize="small"
                                  color="primary"
                                  className="upload-item-icon"
                                />
                                <Typography
                                  variant="body2"
                                  color="textSecondary"
                                  component="span"
                                  key={`Typography_${file.name}`}
                                >
                                  {file.file.name.length > 50
                                    ? `${file.file.name.substring(0, 45)}...`
                                    : file.file.name}
                                </Typography>
                                <IconButton
                                  size="small"
                                  key={`Button_${idx}`}
                                  onClick={() => {
                                    handleUploadFileRemove('regulatoryHistory')
                                  }}
                                >
                                  <DeleteIcon
                                    key={`DeleteButton_${idx}`}
                                    className="upload-item-icon"
                                    fontSize="small"
                                  />
                                </IconButton>
                              </div>
                            )
                          })}
                      </Grid>
                    </Grid>
                  </UploadItem>
                ) : (
                  !isInternal &&
                  !readOnly && (
                    <UploadDragDrop
                      linkText={
                        inputDataForm.values.regulatoryHistory
                          ? 'Replace File'
                          : 'Upload File'
                      }
                      text=" - PDF files only (max. 200mb)"
                      size="small"
                      multiple={false}
                      onDrop={(acceptedFiles) => {
                        handleDroppedFiles(
                          acceptedFiles[0],
                          'regulatoryHistory'
                        )
                      }}
                      maxSize={MAX_SIZE_FILE}
                      onDropRejected={handleFileRejected}
                      accept="application/pdf"
                    />
                  )
                )}
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Card>
      <Card elevation={1} style={{ width: '100%', marginTop: '16px' }}>
        <CardHeader
          title="Legal History Questionnaire"
          subheader="Please answer the following questions in relation to legal action against the company."
        />
        <Grid
          container
          direction="row"
          style={{
            margin: '6px 6px 6px 6px',
            width: '800px',
            marginRight: '20px',
          }}
          spacing={3}
        >
          <Grid item xs={12} md={8}>
            <TextField
              disabled={readOnly}
              data-cy="option-named-defendant-dropdown"
              id="optionNamedDefendant"
              select
              label={`Has your ${organizationVerbiage} been named as a defendant in any litigation and or
              arbitration in the last 3 years?`}
              style={{ width: '100%' }}
              name="optionNamedDefendant"
              onChange={({ target: { value, name } }) => {
                handleChange(name, value, true)
              }}
              InputLabelProps={{
                shrink: true,
              }}
              value={inputDataForm.values.optionNamedDefendant}
              error={!!inputDataForm.errors.optionNamedDefendant}
              helperText={inputDataForm.errors.optionNamedDefendant}
            >
              <MenuItem value="Yes">Yes</MenuItem>
              <MenuItem value="No">No</MenuItem>
              <MenuItem value="N/A">N/A</MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              disabled={readOnly}
              id="input-named-defendant"
              fullWidth
              data-cy="input-named-defendant"
              label="If so, how many times in the last 3 years?"
              name="namedDefendantDetail"
              InputLabelProps={{
                shrink: true,
              }}
              onChange={({ target: { value, name } }) => {
                handleChange(name, value, false)
              }}
              value={inputDataForm.values.namedDefendantDetail}
              error={!!inputDataForm.errors.namedDefendantDetail}
              helperText={inputDataForm.errors.namedDefendantDetail}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <TextField
              disabled={readOnly}
              data-cy="option-named-defendant-breach-consumer-dropdown"
              id="optionNamedDefendantBreachConsumer"
              select
              label={`Has your ${organizationVerbiage} been named as a defendant in the last 3 years for Breach
              of Consumer Privacy or Misappropriation of Confidential Information?`}
              style={{ width: '100%' }}
              name="optionNamedDefendantBreachConsumer"
              onChange={({ target: { value, name } }) => {
                handleChange(name, value, true)
              }}
              InputLabelProps={{
                shrink: true,
              }}
              value={inputDataForm.values.optionNamedDefendantBreachConsumer}
              error={!!inputDataForm.errors.optionNamedDefendantBreachConsumer}
              helperText={
                inputDataForm.errors.optionNamedDefendantBreachConsumer
              }
            >
              <MenuItem value="Yes">Yes</MenuItem>
              <MenuItem value="No">No</MenuItem>
              <MenuItem value="N/A">N/A</MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={12} md={12}>
            <TextField
              disabled={readOnly}
              data-cy="option-named-defendant-any-class-dropdown"
              id="optionNamedDefendantAnyClass"
              select
              label={`Has your ${organizationVerbiage} been named as a defendant in any class action in the last 3
              years?`}
              style={{ width: '100%' }}
              name="optionNamedDefendantAnyClass"
              onChange={({ target: { value, name } }) => {
                handleChange(name, value, true)
              }}
              InputLabelProps={{
                shrink: true,
              }}
              value={inputDataForm.values.optionNamedDefendantAnyClass}
              error={!!inputDataForm.errors.optionNamedDefendantAnyClass}
              helperText={inputDataForm.errors.optionNamedDefendantAnyClass}
            >
              <MenuItem value="Yes">Yes</MenuItem>
              <MenuItem value="No">No</MenuItem>
              <MenuItem value="N/A">N/A</MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={12} md={12}>
            <TextField
              disabled={readOnly}
              data-cy="option-pending-litigation-dropdown"
              id="optionPendingLitigation"
              select
              label={`Is there any pending litigation against senior management or your ${organizationVerbiage} that has the
              potential to cause an adverse material impact on the ${organizationVerbiage}s financials such
              that it would be reported on an audited financial statement?`}
              style={{ width: '100%' }}
              name="optionPendingLitigation"
              onChange={({ target: { value, name } }) => {
                handleChange(name, value, true)
              }}
              InputLabelProps={{
                shrink: true,
              }}
              value={inputDataForm.values.optionPendingLitigation}
              error={!!inputDataForm.errors.optionPendingLitigation}
              helperText={inputDataForm.errors.optionPendingLitigation}
            >
              <MenuItem value="Yes">Yes</MenuItem>
              <MenuItem value="No">No</MenuItem>
              <MenuItem value="N/A">N/A</MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={12} md={12}>
            <Box display="flex" flexDirection="column">
              <Box>
                {isInternal && !inputDataForm.values.legalHistory && (
                  <Typography variant="caption" color="textSecondary">
                    There is no file uploaded for this section.
                  </Typography>
                )}

                {inputDataForm.values.legalHistory &&
                  (!files ||
                    files === null ||
                    files?.length === 0 ||
                    !files.find((f: any) => f.section === 'legalHistory')) && (
                    <Box
                      display="flex"
                      flexDirection="row"
                      style={{ width: '100%' }}
                    >
                      <TextField
                        disabled={readOnly}
                        fullWidth
                        data-cy="dialog-input-field-legalHistory"
                        name="legalHistory"
                        value={
                          inputDataForm.values.legalHistory.length > 80
                            ? `${inputDataForm.values.legalHistory.substring(
                                0,
                                75
                              )}...`
                            : inputDataForm.values.legalHistory
                        }
                        error={!!inputDataForm.errors.legalHistory}
                        helperText={inputDataForm.errors.legalHistory}
                        InputProps={{ readOnly: true }}
                      />

                      <Button
                        style={{ marginLeft: 3 }}
                        size="small"
                        data-cy="download"
                        variant="contained"
                        color="primary"
                        disabled={loading}
                        onClick={() => {
                          handleDownloadSupportingDocument('LegalHistory')
                        }}
                      >
                        Download
                      </Button>
                    </Box>
                  )}
                {!isInternal &&
                files &&
                files.length > 0 &&
                files.find((f: any) => f.section === 'legalHistory') ? (
                  <UploadItem>
                    <Grid container>
                      <Grid item xs={12} md={8}>
                        {files
                          .filter((t: any) => t.section === 'legalHistory')
                          ?.map((file: any, idx: number) => {
                            return (
                              <div
                                className="upload-item-info"
                                key={`div_${idx}`}
                              >
                                <DescriptionIcon
                                  key={`DescriptionIcon_${idx}`}
                                  fontSize="small"
                                  color="primary"
                                  className="upload-item-icon"
                                />
                                <Typography
                                  variant="body2"
                                  color="textSecondary"
                                  component="span"
                                  key={`Typography_${file.name}`}
                                >
                                  {file.file.name.length > 50
                                    ? `${file.file.name.substring(0, 45)}...`
                                    : file.file.name}
                                </Typography>
                                <IconButton
                                  size="small"
                                  key={`Button_${idx}`}
                                  onClick={() => {
                                    handleUploadFileRemove('legalHistory')
                                  }}
                                >
                                  <DeleteIcon
                                    key={`DeleteButton_${idx}`}
                                    className="upload-item-icon"
                                    fontSize="small"
                                  />
                                </IconButton>
                              </div>
                            )
                          })}
                      </Grid>
                    </Grid>
                  </UploadItem>
                ) : (
                  !isInternal &&
                  !readOnly && (
                    <UploadDragDrop
                      linkText={
                        inputDataForm.values.legalHistory
                          ? 'Replace File'
                          : 'Upload File'
                      }
                      text=" - PDF files only (max. 200mb)"
                      size="small"
                      multiple={false}
                      onDrop={(acceptedFiles) => {
                        handleDroppedFiles(acceptedFiles[0], 'legalHistory')
                      }}
                      maxSize={MAX_SIZE_FILE}
                      onDropRejected={handleFileRejected}
                      accept="application/pdf"
                    />
                  )
                )}
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Card>
      <Card elevation={1} style={{ width: '100%', marginTop: '16px' }}>
        <CardHeader
          title="Collection Agency & Agents Questionnaire"
          subheader="Answer the following questions related to third party collection agencies engaged to collect on company's behalf."
        />
        <Grid
          container
          direction="row"
          style={{
            margin: '6px 6px 6px 6px',
            width: '800px',
            marginRight: '20px',
          }}
          spacing={3}
        >
          <Grid item xs={12} md={4}>
            <TextField
              disabled={readOnly}
              data-cy="option-account-litigation-dropdown"
              id="optionAccountLitigation"
              select
              label="Do you outsource accounts for litigation?"
              style={{ width: '100%' }}
              name="optionAccountLitigation"
              onChange={({ target: { value, name } }) => {
                handleChange(name, value, true)
              }}
              InputLabelProps={{
                shrink: true,
              }}
              value={inputDataForm.values.optionAccountLitigation}
              error={!!inputDataForm.errors.optionAccountLitigation}
              helperText={inputDataForm.errors.optionAccountLitigation}
            >
              <MenuItem value="Yes">Yes</MenuItem>
              <MenuItem value="No">No</MenuItem>
              <MenuItem value="N/A">N/A</MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              disabled={readOnly}
              data-cy="option-account-collection-dropdown"
              id="optionAccountCollection"
              select
              label="Do you outsource accounts for collections?"
              style={{ width: '100%' }}
              name="optionAccountCollection"
              onChange={({ target: { value, name } }) => {
                handleChange(name, value, true)
              }}
              InputLabelProps={{
                shrink: true,
              }}
              value={inputDataForm.values.optionAccountCollection}
              error={!!inputDataForm.errors.optionAccountCollection}
              helperText={inputDataForm.errors.optionAccountCollection}
            >
              <MenuItem value="Yes">Yes</MenuItem>
              <MenuItem value="No">No</MenuItem>
              <MenuItem value="N/A">N/A</MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              disabled={readOnly}
              data-cy="option-require-record-calls-dropdown"
              id="optionRequireRecordCalls"
              select
              label="Do you require your agencies to record all calls?"
              style={{ width: '100%' }}
              name="optionRequireRecordCalls"
              onChange={({ target: { value, name } }) => {
                handleChange(name, value, true)
              }}
              InputLabelProps={{
                shrink: true,
              }}
              value={inputDataForm.values.optionRequireRecordCalls}
              error={!!inputDataForm.errors.optionRequireRecordCalls}
              helperText={inputDataForm.errors.optionRequireRecordCalls}
            >
              <MenuItem value="Yes">Yes</MenuItem>
              <MenuItem value="No">No</MenuItem>
              <MenuItem value="N/A">N/A</MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              disabled={readOnly}
              data-cy="option-record-calls-retrieve-dropdown"
              id="optionRecordCallsRetrieve"
              select
              label="Are all recorded calls retrievable within 2 business days?"
              style={{ width: '100%' }}
              name="optionRecordCallsRetrieve"
              onChange={({ target: { value, name } }) => {
                handleChange(name, value, true)
              }}
              InputLabelProps={{
                shrink: true,
              }}
              value={inputDataForm.values.optionRecordCallsRetrieve}
              error={!!inputDataForm.errors.optionRecordCallsRetrieve}
              helperText={inputDataForm.errors.optionRecordCallsRetrieve}
            >
              <MenuItem value="Yes">Yes</MenuItem>
              <MenuItem value="No">No</MenuItem>
              <MenuItem value="N/A">N/A</MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              disabled={readOnly}
              data-cy="option-audit-third-party-dropdown"
              id="optionAuditThirdParty"
              select
              label="Do you conduct audits of your third-party vendors?"
              style={{ width: '100%' }}
              name="optionAuditThirdParty"
              onChange={({ target: { value, name } }) => {
                handleChange(name, value, true)
              }}
              InputLabelProps={{
                shrink: true,
              }}
              value={inputDataForm.values.optionAuditThirdParty}
              error={!!inputDataForm.errors.optionAuditThirdParty}
              helperText={inputDataForm.errors.optionAuditThirdParty}
            >
              <MenuItem value="Yes">Yes</MenuItem>
              <MenuItem value="No">No</MenuItem>
              <MenuItem value="N/A">N/A</MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              disabled={readOnly}
              fullWidth
              id="auditThirdPartyFrequency"
              data-cy="input-audit-third-party-frequency"
              label="Audit third-party frequency"
              name="auditThirdPartyFrequency"
              InputLabelProps={{
                shrink: true,
              }}
              onChange={({ target: { value, name } }) => {
                handleChange(name, value, false)
              }}
              value={inputDataForm.values.auditThirdPartyFrequency}
              error={!!inputDataForm.errors.auditThirdPartyFrequency}
              helperText={inputDataForm.errors.auditThirdPartyFrequency}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              disabled={readOnly}
              data-cy="option-credit-bureau-dropdown"
              id="optionCreditBureau"
              select
              label={`Do you report to the ${
                !isUk ? 'credit bureaus' : 'credit referencing agencies'
              }?`}
              style={{ width: '100%' }}
              name="optionCreditBureau"
              onChange={({ target: { value, name } }) => {
                handleChange(name, value, true)
              }}
              InputLabelProps={{
                shrink: true,
              }}
              value={inputDataForm.values.optionCreditBureau}
              error={!!inputDataForm.errors.optionCreditBureau}
              helperText={inputDataForm.errors.optionCreditBureau}
            >
              <MenuItem value="Yes">Yes</MenuItem>
              <MenuItem value="No">No</MenuItem>
              <MenuItem value="N/A">N/A</MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              disabled={readOnly}
              data-cy="option-account-balances-dropdown"
              id="optionAccountBalances"
              select
              label="Do you add fees to account balances?"
              style={{ width: '100%' }}
              name="optionAccountBalances"
              onChange={({ target: { value, name } }) => {
                handleChange(name, value, true)
              }}
              InputLabelProps={{
                shrink: true,
              }}
              value={inputDataForm.values.optionAccountBalances}
              error={!!inputDataForm.errors.optionAccountBalances}
              helperText={inputDataForm.errors.optionAccountBalances}
            >
              <MenuItem value="Yes">Yes</MenuItem>
              <MenuItem value="No">No</MenuItem>
              <MenuItem value="N/A">N/A</MenuItem>
            </TextField>
          </Grid>
        </Grid>
      </Card>
    </>
  )
}

export default ComplianceLegalRegulatory
