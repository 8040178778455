import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  IconButton,
  MenuItem,
  Paper,
  TextField,
  Typography,
} from '@material-ui/core'
import axios from 'axios'
import { useFormik } from 'formik'
import React, { useContext, useState } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useHistory, useParams } from 'react-router-dom'
import Skeleton from '@material-ui/lab/Skeleton'
import { BACKEND_URL, GetAccessToken } from 'src/api/axios/api'
import * as Yup from 'yup'
import { useSnackbar } from 'notistack'
import { notistackOptions } from 'src/configs/notistackOptions'
import Loader from 'src/components/Loader'
import { KeyboardDatePicker } from '@material-ui/pickers'
import { getLocaleDateString } from 'src/utils/date'
import SOPDraft from 'src/components/SOPDraft'
import {
  getDepartments,
  getInternalUsers,
} from 'src/api/operations/get/business'

import SOPVersioning from 'src/components/SOPVersioning'
import { AuthContext } from 'src/context/AuthenticationContext'
import { USER_ID } from 'src/configs/AuthService'
import { AbilityContext } from 'src/context/Can'
import { PermissionCodeAccess } from 'src/utils/constants'
import { archiveSOPFile } from 'src/api/operations/post/sop'
import { DialogTitleStyled } from 'src/components/Dialogs/style'
import { Close } from '@material-ui/icons'
import { ConfirmDialog } from 'src/components/Dialogs'
import { CORPORATE_SOP_DETAIL } from 'src/routes'
import SOPHistory from 'src/components/SOPHistory'

interface ICorporateSOP {
  name: string
  status: string
  department: string
  owner: string
  nextAuditDueDateUtc?: Date | null
  createDateUtc?: Date | null
  archivedDate?: Date | null
  archiveReason: string
}

const CorporateSOPDetail: React.FC = () => {
  const { id } = useParams<any>()

  const path = window.location.pathname.split('/')
  const cardType = path[path.length - 1]

  const [sopToDownload, setSopToDownload] = useState<any | null>(null)
  const userToken = GetAccessToken()
  const [saving, setSaving] = useState<boolean>(false)
  const { user, userPermissions } = useContext(AuthContext)
  const userId = user.profile[USER_ID]
  const isInternal = userPermissions.type.toLowerCase() === 'internal'
  const { enqueueSnackbar } = useSnackbar()
  const notistackSucces = notistackOptions('success')
  const notistackError = notistackOptions('error')
  const [internalUsers, setInternalUsers] = useState<any[]>([])
  const [lastSOPUpdateData, setLastSOPUpdateData] = useState(null)
  const ability = useContext(AbilityContext)
  const [departments, setDepartments] = useState<any[]>([])
  const currentDate = new Date()
  const nextDay = new Date(currentDate)
  nextDay.setDate(currentDate.getDate() + 1)
  const queryClient = useQueryClient()

  const [openUpdate, setOpenUpdate] = useState(false)

  const [initialValues, setInitialValues] = useState<ICorporateSOP>({
    name: '',
    status: '',
    department: '',
    owner: '',
    createDateUtc: null,
    nextAuditDueDateUtc: null,
    archivedDate: null,
    archiveReason: '',
  })

  useQuery({
    queryKey: ['getInternalUsers'],
    queryFn: async () => {
      const result = await getInternalUsers()

      setInternalUsers(result)
    },
    cacheTime: 0,
  })

  const inputDataFormSchema = Yup.object().shape({
    owner: Yup.string().trim().required('Required'),
    department: Yup.string().trim().required('Required'),
  })

  const canDownloadLatestVersion = ability.can(
    PermissionCodeAccess.ComplianceManagementCorporateSOP_Download,
    'any'
  )
  const getCorporateSOP = useQuery({
    queryKey: ['getCorporateSOP', id],
    cacheTime: 0,
    queryFn: async () => {
      return axios({
        method: 'get',
        url: `${BACKEND_URL}/corporateSOP/getCorporateSOP`,
        params: {
          id,
        },
        headers: {
          Authorization: userToken,
        },
      }).then((result: any) => {
        setInitialValues(result.data)
        setLastSOPUpdateData(result.data)
      })
    },
  })

  const downloadLastSOPVersion = useQuery({
    queryKey: ['downloadLastSOPVersion', sopToDownload],
    cacheTime: 0,
    queryFn: async () => {
      return axios({
        method: 'GET',
        url: `${BACKEND_URL}/corporateSOP/downloadLastSOPVersion`,
        params: {
          id,
        },
        headers: {
          Authorization: userToken,
        },
      }).then((result: any) => {
        setSopToDownload(null)
        window.location.href = result.data || ''
      })
    },
    enabled: sopToDownload !== null,
  })
  const handleDownloadFile = () => {
    setSopToDownload(id)
  }

  const userDepartment = internalUsers?.find(
    (r) => r.Id.toLowerCase() === userId.toLowerCase()
  )?.Department

  const isCompliance = internalUsers?.find(
    (r) => r.Id.toLowerCase() === userId.toLowerCase()
  )?.isCompliance
  const history = useHistory()

  const [openArchive, setOpenArchive] = useState(false)
  const [archiveReason, setArchiveReason] = useState<string>('')

  const mutationArchiveSOP = useMutation({
    mutationFn: async () => {
      await archiveSOPFile(id, archiveReason)
      setOpenArchive(false)
      setArchiveReason('')
      enqueueSnackbar('SOP has been archived.', notistackOptions('success'))
    },
  })

  const inputDataForm = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: inputDataFormSchema,
    onSubmit: (values, { setSubmitting }) => {
      const requestValues = {
        id,
        name: values.name || null,
        status: values.status || null,
        department: values.department || null,
        owner: values.owner,
        ownerId:
          internalUsers.find((f: any) => f.DisplayName === values.owner)?.Id ||
          '',
        nextAuditDueDateUtc: values.nextAuditDueDateUtc || null,
        createDateUtc: values.createDateUtc,
      }

      setSaving(true)
      axios({
        method: 'post',
        url: `${BACKEND_URL}/corporatesop`,
        data: requestValues,
        headers: {
          Authorization: userToken,
        },
      })
        .then(() => {
          enqueueSnackbar('SOP updated.', notistackSucces)
          queryClient.refetchQueries({
            queryKey: 'getCorporateSOPGrid',
          })
          setSaving(false)
        })
        .catch((error) => {
          enqueueSnackbar('Error on update SOP.', notistackError)
          setSaving(false)
        })

      setSubmitting(false)
    },
  })

  useQuery({
    queryKey: ['getDepartments'],
    queryFn: async () => {
      const result = await getDepartments()

      setDepartments(result)
    },
    cacheTime: 0,
  })

  const SendToReview = useMutation({
    mutationFn: async () => {
      return axios({
        method: 'post',
        url: `${BACKEND_URL}/corporateSOP/SendToReview`,
        params: {
          id,
        },
        headers: {
          Authorization: userToken,
        },
      })
        .then(() => {
          enqueueSnackbar('SOP returned to redline.', notistackSucces)
          queryClient.refetchQueries({
            queryKey: 'getCorporateSOPGrid',
          })
          queryClient.refetchQueries({
            queryKey: 'getCorporateSOPCards',
          })
          queryClient.refetchQueries({
            queryKey: 'getCorporateSOP',
          })
          history.push(`${CORPORATE_SOP_DETAIL}/${id}/ready-for-redlines`)
        })
        .catch((error: any) => {
          enqueueSnackbar(error.response.data, notistackError)
        })
    },
    onError: () => {
      enqueueSnackbar('Error on sending to review.', notistackError)
    },
  })

  const handleSendToReview = () => {
    SendToReview.mutate()
    setOpenUpdate(false)
  }

  const availableOwners: any[] =
    (inputDataForm.values.department &&
      internalUsers &&
      internalUsers.filter(
        (i: any) =>
          i.Department !== null &&
          i.Department?.toLowerCase() ===
            inputDataForm.values.department?.toLowerCase()
      )) ||
    []

  const canUpload =
    isCompliance ||
    (userId.toLowerCase() === inputDataForm.values.owner.toLowerCase() &&
      ability.can(
        PermissionCodeAccess.ComplianceManagementCorporateSOP_Upload,
        'any'
      ))

  const loading = getCorporateSOP.isLoading
  return (
    <>
      <Box padding={4} mt={4}>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={12}>
            <Typography variant="subtitle1">Corporate SOP Detail</Typography>
          </Grid>
        </Grid>
      </Box>

      <form onSubmit={inputDataForm.handleSubmit}>
        <Grid container md={12} sm={12}>
          <Grid item xs={12}>
            <Paper style={{ margin: 10, padding: 5 }}>
              <Box m={5}>
                {loading && <Loader />}

                <Grid container spacing={3}>
                  <Grid item xs={12} md={4}>
                    <TextField
                      fullWidth
                      data-cy="dialog-input-field-name"
                      label="Name"
                      name="name"
                      disabled={true}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{
                        maxLength: 100,
                      }}
                      onChange={inputDataForm.handleChange}
                      value={inputDataForm.values.name}
                      error={!!inputDataForm.errors.name}
                      helperText={inputDataForm.errors.name}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <TextField
                      fullWidth
                      data-cy="dialog-input-field-status"
                      label="Status"
                      name="status"
                      disabled={true}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{
                        maxLength: 100,
                      }}
                      onChange={inputDataForm.handleChange}
                      value={inputDataForm.values.status}
                      error={!!inputDataForm.errors.status}
                      helperText={inputDataForm.errors.status}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <KeyboardDatePicker
                      style={{ width: '100%' }}
                      format={getLocaleDateString()}
                      id="createDateUtc"
                      disabled={true}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      label="Create Date"
                      name="createDateUtc"
                      onChange={(date: any) => {
                        inputDataForm.setFieldValue('createDateUtc', date, true)
                      }}
                      value={inputDataForm.values.createDateUtc}
                      error={!!inputDataForm.errors.createDateUtc}
                      helperText={<>{inputDataForm.errors.createDateUtc}</>}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <TextField
                      disabled={!canUpload || saving}
                      fullWidth
                      label="Department"
                      name="department"
                      select
                      onChange={inputDataForm.handleChange}
                      error={!!inputDataForm.errors.department}
                      value={inputDataForm.values.department}
                      helperText={<>{inputDataForm.errors.department}</>}
                    >
                      {departments?.map((option) => (
                        <MenuItem key={option.type} value={option.type}>
                          {option.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <TextField
                      disabled={!canUpload || saving}
                      fullWidth
                      label="Owner"
                      name="owner"
                      select
                      onChange={inputDataForm.handleChange}
                      error={!!inputDataForm.errors.owner}
                      value={inputDataForm.values.owner}
                      helperText={<>{inputDataForm.errors.owner}</>}
                    >
                      {availableOwners?.map((option) => (
                        <MenuItem key={option.Id} value={option.DisplayName}>
                          {option.DisplayName}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <KeyboardDatePicker
                      disabled={!canUpload}
                      style={{ width: '100%' }}
                      format={getLocaleDateString()}
                      id="nextAuditDueDateUtc"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      label="Next Audit Due Date"
                      name="nextAuditDueDateUtc"
                      onChange={(date: any) => {
                        inputDataForm.setFieldValue(
                          'nextAuditDueDateUtc',
                          date,
                          true
                        )
                      }}
                      minDate={nextDay}
                      value={inputDataForm.values.nextAuditDueDateUtc || null}
                      error={!!inputDataForm.errors.nextAuditDueDateUtc}
                      helperText={
                        <>{inputDataForm.errors.nextAuditDueDateUtc}</>
                      }
                    />
                  </Grid>
                  {inputDataForm.values.archivedDate && (
                    <Grid item xs={12} md={4}>
                      <KeyboardDatePicker
                        disabled={!canUpload}
                        style={{ width: '100%' }}
                        format={getLocaleDateString()}
                        id="archivedDate"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        label="Archive Date"
                        name="archivedDate"
                        onChange={(date: any) => {
                          inputDataForm.setFieldValue(
                            'archivedDate',
                            date,
                            true
                          )
                        }}
                        minDate={nextDay}
                        value={inputDataForm.values.archivedDate || null}
                        error={!!inputDataForm.errors.archivedDate}
                        helperText={<>{inputDataForm.errors.archivedDate}</>}
                      />
                    </Grid>
                  )}

                  {inputDataForm.values.archivedDate && (
                    <Grid item xs={12} md={8}>
                      <TextField
                        fullWidth
                        multiline
                        maxRows={4}
                        data-cy="dialog-input-field-status"
                        label="Archive Reason"
                        name="archiveReason"
                        disabled={true}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        inputProps={{
                          maxLength: 100,
                        }}
                        onChange={inputDataForm.handleChange}
                        value={inputDataForm.values.archiveReason}
                        error={!!inputDataForm.errors.archiveReason}
                        helperText={inputDataForm.errors.archiveReason}
                      />
                    </Grid>
                  )}
                </Grid>
              </Box>
              {!loading ? (
                <Box
                  mt={5}
                  display="flex"
                  flexDirection="row"
                  justifyContent="flex-end"
                >
                  {isInternal && cardType === 'master' && canUpload && (
                    <Button
                      style={{ marginRight: 5 }}
                      data-cy="update-sop"
                      variant="contained"
                      color="primary"
                      type="button"
                      disabled={downloadLastSOPVersion.isLoading}
                      onClick={() => setOpenUpdate(true)}
                    >
                      Update SOP
                    </Button>
                  )}
                  {isInternal &&
                    cardType === 'master' &&
                    !inputDataForm.values.archivedDate && (
                      <Button
                        style={{ marginRight: 5 }}
                        data-cy="archive-button"
                        variant="contained"
                        color="primary"
                        type="button"
                        disabled={downloadLastSOPVersion.isLoading}
                        onClick={() => setOpenArchive(true)}
                      >
                        Archive SOP
                      </Button>
                    )}
                  {cardType === 'master' && canDownloadLatestVersion && (
                    <Button
                      style={{ marginRight: 5 }}
                      data-cy="download-last-button"
                      variant="contained"
                      color="primary"
                      type="button"
                      disabled={downloadLastSOPVersion.isLoading}
                      onClick={() => handleDownloadFile()}
                    >
                      Download SOP
                    </Button>
                  )}
                  {isInternal && (
                    <Button
                      style={{ marginRight: 5 }}
                      data-cy="submit-button"
                      variant="contained"
                      color="primary"
                      type="submit"
                      disabled={!canUpload}
                    >
                      Submit
                    </Button>
                  )}
                </Box>
              ) : (
                <Box
                  mt={5}
                  display="flex"
                  flexDirection="row"
                  justifyContent="flex-end"
                >
                  <Skeleton
                    variant="rect"
                    width={90}
                    height={40}
                    style={{ marginRight: 5 }}
                  />
                  <Skeleton
                    variant="rect"
                    width={90}
                    height={40}
                    style={{ marginRight: 5 }}
                  />
                  <Skeleton
                    variant="rect"
                    width={90}
                    height={40}
                    style={{ marginRight: 5 }}
                  />
                </Box>
              )}
            </Paper>
          </Grid>
          {cardType === 'master' && lastSOPUpdateData !== null && (
            <Grid item xs={12} md={6} lg={6}>
              <SOPHistory
                department={userDepartment}
                sop={lastSOPUpdateData}
                isCompliance={isCompliance}
              />
            </Grid>
          )}
          {cardType !== 'master' && lastSOPUpdateData !== null && (
            <Grid item xs={12} md={6} lg={6}>
              <SOPVersioning
                department={userDepartment}
                sop={lastSOPUpdateData}
                isCompliance={isCompliance}
              />
            </Grid>
          )}
          {cardType !== 'master' && lastSOPUpdateData !== null && (
            <Grid item xs={12} md={6} lg={6}>
              <SOPDraft
                sop={lastSOPUpdateData}
                department={userDepartment}
                isCompliance={isCompliance}
              />
            </Grid>
          )}
        </Grid>
      </form>

      {openUpdate && (
        <ConfirmDialog
          open={openUpdate}
          title="Update SOP"
          loading={SendToReview.isLoading}
          description={
            <>
              <Typography variant="body1" color="textSecondary">
                By confirmin this action this SOP will return to the redlines
                phase for updates. Do you confirm you want to update this SOP?
              </Typography>
            </>
          }
          closeName="Cancel"
          confirmName="Continue"
          onClose={() => setOpenUpdate(false)}
          onConfirm={handleSendToReview}
        />
      )}

      {openArchive && (
        <Dialog
          open={openArchive}
          onClose={() => {
            setOpenArchive(false)
            setArchiveReason('')
          }}
          aria-labelledby="dialog-archive-sop"
          aria-describedby="dialog-archive-sop"
          maxWidth="md"
        >
          <DialogTitleStyled id="dialog-archive-sop">
            <Typography
              variant="body1"
              style={{
                color: 'white',
                fontSize: '1.5rem',
                fontWeight: 400,
                lineHeight: 1.2,
                textAlign: 'center',
              }}
            >
              Archive SOP Reason
            </Typography>
            <IconButton
              aria-label="close"
              onClick={() => {
                setOpenArchive(false)
                setArchiveReason('')
              }}
              style={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: 'white',
              }}
            >
              <Close />
            </IconButton>
          </DialogTitleStyled>
          <DialogContent>
            <TextField
              multiline={true}
              minRows={5}
              variant="outlined"
              fullWidth
              inputProps={{ maxLength: 400 }}
              name="reason"
              style={{ width: 400 }}
              value={archiveReason}
              onChange={(e) => setArchiveReason(e.target.value)}
              placeholder="Reason"
              disabled={mutationArchiveSOP.isLoading}
            />
            <Typography
              style={{
                color: 'silver',
                textAlign: 'right',
              }}
            >
              {`${archiveReason?.length ?? 0}/400`}
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button
              variant="outlined"
              color="secondary"
              onClick={() => {
                setOpenArchive(false)
                setArchiveReason('')
              }}
              disabled={mutationArchiveSOP.isLoading}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              disabled={!archiveReason || mutationArchiveSOP.isLoading}
              onClick={() => mutationArchiveSOP.mutate()}
            >
              Submit
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  )
}

export default CorporateSOPDetail
