import { httpClient } from 'src/api/axios/api'
import {
  GetActiveSOPs,
  GetBusinessesBySOP,
  GetBusinessSOPs,
  GetSOPByBusiness,
} from './url'

export const getActiveSOPs = async (): Promise<any> => {
  const response = await httpClient.get(GetActiveSOPs)
  return response?.data
}

export const getBusinessSOPs = async (
  businessId: string | undefined
): Promise<any> => {
  const response = await httpClient.get(GetBusinessSOPs, {
    params: {
      businessId,
    },
  })
  return response?.data
}

export const getBusinessesBySOP = async (
  sopId: string | undefined
): Promise<any> => {
  const response = await httpClient.get(GetBusinessesBySOP, {
    params: {
      sopId,
    },
  })
  return response?.data
}

export const getSOPByBusiness = async (
  businessId: string | undefined
): Promise<any> => {
  const response = await httpClient.get(GetSOPByBusiness, {
    params: {
      businessId,
    },
  })
  return response?.data
}
